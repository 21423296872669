let prefix = '/api/sap/item-groups';
function load_groups(){
    return window.axios.get(prefix+'/GroupsWarehouse/index');
}
function getItemsGropus(){
    return window.axios.get(prefix+'/index');
}
export default {
    load_groups,
    getItemsGropus,
}