const namespaced = true
import api from '../../../../api/sap/payment/outgoing_sap.js';

const state = {
    loading: false,
    payments_outgoing_sap: [],
    payment_outgoing: [],
}

const mutations = {
    SET_LOADING(state, val) {
        state.loading = val;
    },
    MUTATION_SET_PAYMENTS_OUTGOING_SAP(state, data){
        state.payments_outgoing_sap = data;
    },
    MUTATION_SET_PAYMENT_OUTGOING_SAP(state, data){
        state.payment_outgoing = data;
    },

}
const actions = {
    getPaymentsOutgoing(context, data){
        api.getPaymentOutgoing(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_PAYMENTS_OUTGOING_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    getPaymentOutgoing(context, data){
        api.show(data).then(function (res) {
            context.commit('MUTATION_SET_PAYMENT_OUTGOING_SAP', res.data);
        }).catch((e) => {
            context.commit('MUTATION_SET_PAYMENT_OUTGOING_SAP', []);
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    setClear(context, data){
       context.commit('MUTATION_SET_PAYMENT_OUTGOING_SAP',data)
    },
}

const getters = {
    getDocTotalPay(state) {
        return (state.payment_outgoing.length>0) ? parseFloat(state.payment_outgoing[0].DocTotal) : 0
    },

}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}