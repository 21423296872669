const namespaced = true


const state = {
    configured_warehouses : [],
}
const mutations = {
     // Name, Property
    MUTATION_SET_CONFIGURED_WAREHOUSES(state, data){
         state.configured_warehouses = data;
    }
}
const actions = {
     // Name Property
    setConfiguredWarehouses(context,data) {
        context.commit('MUTATION_SET_CONFIGURED_WAREHOUSES', data);
    },
}

const getters = {
    getWarehouseItem(state){
        return state.configured_warehouses
    }
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}