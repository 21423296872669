const namespaced = true
import _ from 'lodash';

const state = {
    invoices : []
}
const mutations = {
     // Name, Property
    MUTATION_SET_INVOICES(state, data){
         state.invoices = data;
    },
    MUTATION_CLEAR(state){
        state.invoices = [];
    }
}
const actions = {
    // Name Property
    process({commit, rootState}, { type }) {
        const general = rootState["invoice_customer_sap"];        
        let tmp = _.filter(general.invoices_customer_sap_dashboard.invoices, function (o) { return o.ItmsGrpCod == type; });
        commit('MUTATION_SET_INVOICES', tmp);
    },
    clear(context){
        context.commit("MUTATION_CLEAR");
    }
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}