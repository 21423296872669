<template>
    <v-container>
        <v-row>
            <v-col class="text-center page-not-found">
                <div class="text-h1 transition-swing">
                    Oops!..
                </div>
                <div class="grey--text my-3">
                    No tienes permisos para accesar o no existe la ruta.
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>  
    import { mapActions } from 'vuex';
    export default {
        props : [''],
        data() { return {} },
        components: {},
        methods: {
            ...mapActions('authentication', ['check_token']),
        },
        beforeMount(){
            this.check_token();
        },
        mounted (){},
        computed: {},
        watch : {}
    }
</script>
  
<style scoped>
    .page-not-found {
        position: fixed;
        width: 80%;
        height: 50%;
        top: 30%;
        left: 10%;
    }
</style>