
let prefix = '/api/purchaseRules';

function getDistributionXSlpCode(id){
    return window.axios.get(prefix+'/getDistributionXSlpCode/'+id);
}
function addBuyers(data){
    return window.axios.post(prefix+'/addBuyers', data);
}
function removeBuyers(id){
    return window.axios.delete(prefix+'/removeBuyers/'+ id);
}
function getUserRules(){
    return window.axios.get(prefix+'/userRules');
}
export default {
    getDistributionXSlpCode,addBuyers,removeBuyers,getUserRules
}