const namespaced = true
import api from '../../../api/sap/goods-issue';

const state = {
    goods_issue_sap : []
}

const mutations = {
    MUTATION_SET_GOODS_ISSUE_SAP(state, goods_issue_sap) {
        state.goods_issue_sap = goods_issue_sap
    }
}
const actions = {
    store(context, data) {
        return new Promise((resolve) => {
            api.store(data).then(function (res) {
                context.commit('MUTATION_SET_GOODS_ISSUE_SAP', res.data);
                resolve(res.data);
            }).catch((e) => {
                console.log(e);
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
            })
        })
    },

}

const getters = {
    goods_issue_sap(state){
        return state.goods_issue_sap.correct
    }

}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}

