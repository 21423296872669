const namespaced = true

import api from '../../api/invoices.js';
import _ from 'lodash';
import { downloadFile } from './../../src/helpers/util.js'

const state = {
    invoiceList: [],
    loading: false,
    documents: {
        Orden: [],
        Factura: [],
        Entrada: []
    },
    bannerDetail: false,
    invoice: null,
    loadingDownload: false,
    searchSelect: '',
}
const mutations = {
    SET_INVOICE_LIST(state, data) {
        state.invoiceList = data;
    },
    SET_LOADING(state, data) {
        state.loading = data;
    },
    SET_LOADING_DOWNLOAD(state, data) {
        state.loadingDownload = data;
    },
    SET_DOCUMENTS(state, data) {
        state.documents = data;
    },
    SET_BANNER_DETAIL(state, value) {
        state.bannerDetail = value;
    },
    SET_INVOICE(state, data) {
        state.invoice = data;
    },
    SET_SEARCH_SELECT(state, value) {
        state.searchSelect = value;
    },
}
const actions = {
    getListByStatus(context, data) {
        context.commit('SET_LOADING', true);
        api.getList(data.cardName, data.dateIni, data.dateFin).then(function (res) {
            context.commit('SET_INVOICE_LIST', res.data)
            context.commit('SET_LOADING', false);
        }).catch(function (error) {
            context.commit('SET_INVOICE_LIST', [])
            console.log(error)
            context.commit('SET_LOADING', false);
        })
    },
    getDocuments(context, data) {
        context.commit('SET_LOADING', true);
        api.getDocuments(data).then(function (res) {
            context.commit('SET_DOCUMENTS', res.data)
            context.commit('SET_LOADING', false);
        }).catch(function (error) {
            console.log(error)
            context.commit('SET_LOADING', false);
        })
    },
    clear(context) {
        context.commit('SET_LOADING', true);
        context.commit('SET_DOCUMENTS', { Orden: [], Factura: [], Entrada: [] });
        context.commit('SET_LOADING', false);
    },
    setBannerDetails(context, value) {
        context.commit('SET_BANNER_DETAIL', value);
    },
    setInvoice(context, data) {
        context.commit('SET_INVOICE', data);
    },
    setClear(context) {
        context.commit('SET_INVOICE_LIST', []);
        context.commit('SET_SEARCH_SELECT', '');
    },
    getInvoiceExport(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING_DOWNLOAD', true);
            api.getListExport(data).then(function (res) {
                downloadFile(res.data, 'Facturas pagadas.xlsx');
                context.commit('SET_LOADING_DOWNLOAD', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING_DOWNLOAD', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })

    },
    setSearchSelect(context, value) {
        context.commit('SET_SEARCH_SELECT', value);
    },
}

const getters = {
    getDocumentEntries(state) {

        let docEntries = _.uniq(_.map(state.documents.Entrada, 'DocEntry'));
        let entries = [];
        _.forEach(docEntries, docEntry => {
            let entryObj = _.find(state.documents.Entrada, entry => { return entry.DocEntry == docEntry; });
            entries.push(entryObj);

        });
        return entries
    },
    getDocumentPurchaseOrders(state) {
        let docPurchaseOrders = _.uniq(_.map(state.documents.Orden, 'DocEntry'));
        let purchaseOrder = [];
        _.forEach(docPurchaseOrders, docEntry => {
            let purchaseOrderObj = _.find(state.documents.Orden, order => { return order.DocEntry == docEntry; });
            purchaseOrder.push(purchaseOrderObj);

        });
        return purchaseOrder
    },
    getDataTableExport(state) {
        let transformData = _.map(state.invoiceList, item => [
            item.doc_num,
            item.NumATCard,
            item.lote,
            item.nombre_beneficiario,
            item.SubTotal,
            item.Impuestos,
            item.Retenciones,
            item.SubTotal + item.Impuestos - item.Retenciones,
            item.divisa_operacion,
            item.updated_at,

        ]);
        return transformData;
    },
    dataWithTotals(state) {
        const totalImpuestos = _.sumBy(state.invoiceList, 'Impuestos');
        const totalRetenciones = _.sumBy(state.invoiceList, 'Retenciones');
        const totalSubTotal = _.sumBy(state.invoiceList, 'SubTotal');
        const totalDocTotal = _.sumBy(state.invoiceList, 'DocTotal');

        return [
            ...state.invoiceList,
            {
                doc_num: 'Totales',
                lote: null,
                nombre_beneficiario: null,
                Impuestos: totalImpuestos,
                Retenciones: totalRetenciones,
                SubTotal: totalSubTotal,
                DocTotal: totalDocTotal,
                divisa_operacion: null,
                Fecha: null,


            },
        ];
    },

}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}