<template>    
    <v-app>
        <navigation-menu v-if="currentRoute != 'login'"></navigation-menu>
        <v-main>
            <router-view v-if="canSeeView()"></router-view>
            <page-not-found v-else></page-not-found>
        </v-main>       

        <v-snackbar top right outlined multi-line style="white-space: pre-line" v-model="notification.visibility" :color="notification.color" :timeout="notification.timeout">
            {{ notification.text }}
            <template v-slot:action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="notification.visibility = false" :color="notification.color">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>        
    </v-app>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import { can } from '../src/helpers/permissions';
    import NavigationMenu from './shared/NavigationMenu.vue';
    import PageNotFound from './shared/PageNotFound.vue';

    export default {
        name: 'App',
        components: {
            NavigationMenu,
            PageNotFound,
        },
        data: () => ({}),
        methods: {
            ...mapActions("authentication", ["check_token"]),
            ...mapActions('configuration', ['resetConfiguration']),
            ...mapActions('notifications',  ['clear']),            
            /**
             * Solo en el caso en que quisieramos ocultarlo en una determinada ruta.
             */
            isShow(){
                return (this.$route.fullPath != '/') ? true : false;
            },
            canSeeView(){
                return (this.$route.meta.permissions)?can(this.getAbilitiesUser, this.$route.meta.permissions):true;
            },
        },
        computed: {
            ...mapGetters('configuration', ['getAbilitiesUser']),
            ...mapState('notifications', ['notification']),
            currentRoute(){
                return this.$route.name;
            },
        },
        mounted() {},
        beforeMount (){
            this.clear();
            this.check_token();
        },
    }
</script>
