const namespaced = true

import api from '../../../api/walmart/deliveries.js';


const state = {
     CatLitterPod : [],
     flagCatLitter : false
}
const mutations = {
     MUTATION_SET_CAT_LITTER_POD(state, value) {
          state.CatLitterPod = value;
     },
     MUTATION_FLAG_CAT_LITTER(state, value){
          state.flagCatLitter =(value)
     },
}
const actions = {
      getCatLitterPOD(context, data) {
          context.commit('MUTATION_FLAG_CAT_LITTER',true);
          return new Promise((resolve) => { 
               api.getCatLitterPOD(data).then(function (res) {
                    context.commit('MUTATION_SET_CAT_LITTER_POD', res.data);
                    context.commit('MUTATION_FLAG_CAT_LITTER',false);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
                    context.commit('MUTATION_FLAG_CAT_LITTER',false);
               })
          })
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
