
let prefix = '/api/finance';

function getList(cardName,dateIni,dateFin){
    return window.axios.get(prefix+'/invoices/state?cardName='+cardName+'&dateIni='+dateIni+'&dateFin='+dateFin);
}
function getDocuments({DocEntry ,DocNum ,Type}){
    return window.axios.get(prefix+'/authorizations/invoice/getAll?DocEntry='+DocEntry+'&DocNum='+DocNum+'&Type='+Type);
}
function getListExport(data){
    return window.axios.post(prefix+'/invoices/state/export',data,{ responseType: 'blob' });
}

export default {
    getList,getDocuments,getListExport
}