
let prefix = '/api/calendarFiles/Companies';

function getCompanies(){
    return window.axios.get(prefix+'/index');
}

function store(data){
    return window.axios.post(prefix+'/store',data);
}

function update(id,data){
    return window.axios.patch(prefix+'/'+ id + '/update/', data);
}

export default {
    getCompanies,store,update
    
}