let prefix = '/api/ability';

function getAbilities(all = false) {
    return (all)?window.axios.get('/api/ability/index'):window.axios.get('/api/user/abilities');
}
function store(data) {
    return window.axios.post(prefix+'/store', data)
}
function update(id, data) {
    return window.axios.post(prefix+'/'+id+'/update', data);
}
function deleted(id){
    return window.axios.post(prefix+'/'+id+'/delete');
}
export default {
    getAbilities,
    store,
    update,
    deleted,
}