
let prefix = '/api/voucher';

function getVouncher(){
    return window.axios.get(prefix+'/index');
}
function store(data){
    return window.axios.post(prefix+'/store', data);
}
function update(data){
    return window.axios.patch(prefix+'/update',data);
}
function addGoodsIssue(data){
    return window.axios.patch(prefix+'/addGoodsIssue',data);
}
function updateLine(data){
    return window.axios.patch(prefix+'/updateLine',data);
}
function updateCostCenter(data){
    return window.axios.patch(prefix+'/updateCostCenter',data);
}
export default {
    getVouncher,store,update,addGoodsIssue,updateLine,updateCostCenter
}