
function login_server(data){
    return window.axios.post('/api/auth/login', data);
}
function login(data){
    return window.axios.post('/api/login', data);
}
function loginByEmail(data){
    return window.axios.post('/api/email/login', data);
}
function registerByGoogle(data){
    return window.axios.post('/api/google/register', data);
}
function registerByEmail(data){
    return window.axios.post('/api/email/register', data);
}
function check_token(){
    return window.axios.get('/api/auth/check/token');
}
function logout (){
    return window.axios.get('/api/auth/logout');

}
export default {
    login_server,login,registerByGoogle,registerByEmail,loginByEmail,logout,check_token
}