const namespaced = true
import api from '../../api/vounchers.js';

const state = {
    request: {
        request_code : null,
        whs_data: {},
        const_center_code:{},
        user_code: null,
        applicant_name:null,
        date : null,
        itm_grp_code:null,
        lines :[],
    },
    vouncher:[]
}
const mutations = {
    SET_REQUEST_ITEM_GROUP( state, request ){
        state.request.itm_grp_code = request
    },
    SET_REQUEST_WHS_DATA(state , request){
        state.request.whs_data = request
    },
    SET_REQUEST_APPLICANT_NAME(state , request){
        state.request.applicant_name = request
    },
    SET_REQUEST_CONST_CENTER_CODE(state , request){
        state.request.const_center_code = request
    },
    SET_REQUEST_USER_CODE(state , request){
        state.request.user_code = request
    },
    SET_REQUEST_DATE(state , request){
        state.request.date = request
    },
    SET_REQUEST_LINES(state , request){
        state.request.lines = request
    },
    SET_REQUEST_RESET(state){
        state.request.request_code = null,
        state.request.whs_data =  {},
        state.request.const_center_code={},
        state.request.user_code= null,
        state.request.applicant_name=null,
        state.request.date = null,
        state.request.itm_grp_code=null,
        state.request.lines =[]
    },
    SET_REQUEST_VOUNCHER(state,request){
        state.vouncher = request;
    },
    SET_REQUEST_CODE(state,request){
        state.request_code = request
    }
}
const actions = {
    setRequestItemGroup(context , request){
        context.commit('SET_REQUEST_ITEM_GROUP', request )
     },
     setRequestWhsData(context , request){
        context.commit('SET_REQUEST_WHS_DATA', request )
     },
     setRequestApplicantName(context , request){
        context.commit('SET_REQUEST_APPLICANT_NAME', request )
     },
     setRequestCostcenterCode(context , request){
        context.commit('SET_REQUEST_CONST_CENTER_CODE', request )
     },
     setRequestUserCode(context , request){
        context.commit('SET_REQUEST_USER_CODE', request )
     },
     setRequestDate(context , request){
        context.commit('SET_REQUEST_DATE', request )
     },
     setRequestLines(context , request){
        context.commit('SET_REQUEST_LINES', request )
     },
     setRequestReset(context){
        context.commit('SET_REQUEST_RESET')
     },
     getVouncher(context, all = false) {
        return new Promise((resolve) => {
            api.getVouncher(all).then(function (res) {
                context.commit('SET_REQUEST_VOUNCHER', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
                
            })
        })
    },
    store(context, data){
        return new Promise((resolve, reject) => {
            api.store(data).then(resp => {
                resolve(resp.data);
            }).catch((e) => {
                reject(e)
            })
        });
    },
    update(context, data){
        return new Promise((resolve, reject) => {
            api.update(data).then(resp => {
                resolve(resp.data);
            }).catch((e) => {
                reject(e)
            })
        });
    },
    updateCostCenter(context, data){
        return new Promise((resolve, reject) => {
            api.updateCostCenter(data).then(resp => {
                resolve(resp.data);
            }).catch((e) => {
                reject(e)
            })
        });
    },
    addGoodsIssue(context, data){
        return new Promise((resolve, reject) => {
            api.addGoodsIssue(data).then(resp => {
                resolve(resp.data);
            }).catch((e) => {
                reject(e)
            })
        });
    },
    updateLine(context, data){
        return new Promise((resolve, reject) => {
            api.updateLine(data).then(resp => {
                resolve(resp.data);
            }).catch((e) => {
                reject(e)
            })
        });
    },
    
}
const getters = {
    getRequest(state){
        return state.request
    },
    getListRequest(state){
        return state.vouncher
    },
    getRequestCode(state){
        return state.request_code
    }

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}