let prefix = '/api/sap/outgoing-payments';

function getPaymentOutgoing(date1, date2){
    return window.axios.get(prefix+'/index?date1='+date1+'&date2='+date2);
}
function show(docEntry){
    return window.axios.get(prefix+'/show/'+docEntry);
}
export default {
    getPaymentOutgoing,show
}