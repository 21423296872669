let prefix = '/api/sap/business-partners';

function search(cardCode) {
    return window.axios.get(prefix+'/search/'+cardCode)
}
function showByCardType(cardType) {
    return window.axios.get(prefix+'/show/'+cardType)
}
export default {
    search,showByCardType
}