const namespaced = true
import api from '../../api/calendarFile.js';
import apiGoogle from '../../api/googleDrive.js';
import apiEvidences from './../../api/evidences.js';
import { downloadFile } from '../../src/helpers/util.js'
import { can } from './../../src/helpers/permissions.js';
import _ from 'lodash';

const state = {
    calendarFiles:[],
    calendarFilesDocumentNew:[],
    calendarFilesDocument:[],
    events: [],
    loading: false,
    loadingDownload: false,
    loadingAddFile: false,
    flagDialog: false,
}
const mutations = {
    MUTATION_SET_CALENDAR_FILES(state, data) {
        // Si es root-document Mostrar todos los documentos
        if (can(data.root.configuration.userAuth.abilities, 'root-calendar')) {
            state.calendarFiles = _.orderBy(data.data, ['current']);
        } else if (can(data.root.configuration.userAuth.abilities, 'responsible-group-calendar')) {
            let temporal = [];
            let userID = data.root.configuration.userAuth.id;
            let GroupsUserID = _.map(_.filter(data.root.calendarGroupsUser.usersResponsible, ['user.id', userID]), 'group')
            _.forEach(data.data, function (document) {
                _.forEach(GroupsUserID, function (group) {
                    if (group.id == document.group.id) {
                        temporal.push(document)
                    }
                });
            });
            state.calendarFiles = _.orderBy(temporal, ['current']);
            //Si es responsibleGroupCalendar Mostrar todos los documentos de su grupo
        } else if (can(data.root.configuration.userAuth.abilities, 'responsible-document')) {
            //Si es responsibleDocument Mostrar unicamente los documentos donde es responsable
            state.calendarFiles = _.orderBy(_.filter(data.data, function (o) { return o.is_responsible; }), ['current']);
        }
    },
    MUTATION_SET_EVENTS(state, data) {
        const events = []
        let tempo = [];
        if (can(data.root.configuration.userAuth.abilities, 'root-calendar')) {
            tempo = _.orderBy(data.data, ['current']);
        } else if (can(data.root.configuration.userAuth.abilities, 'responsible-group-calendar')) {
            let temporal = [];
            let userID = data.root.configuration.userAuth.id;
            let GroupsUserID = _.map(_.filter(data.root.calendarGroupsUser.usersResponsible, ['user.id', userID]), 'group')
            _.forEach(data.data, function (document) {
                _.forEach(GroupsUserID, function (group) {
                    if (group.id == document.group.id) {
                        temporal.push(document)
                    }
                });
            });
            tempo = _.orderBy(temporal, ['current']);
            //Si es responsibleGroupCalendar Mostrar todos los documentos de su grupo
        } else if (can(data.root.configuration.userAuth.abilities, 'responsible-document')) {
            //Si es responsibleDocument Mostrar unicamente los documentos donde es responsable
            tempo = _.orderBy(_.filter(data.data, function (o) { return o.is_responsible; }), ['current']);
        }
        _.forEach(tempo, function (document) {
            let date = new Date(document.initial_date);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
            events.push({
                name: document.name,
                start: date,
                color: document.current,
                data: document,
                group: document.group.name,
                users: _.map(document.assign, 'user.name'),
            })
        });
        this.events = events
        state.events = events;
    },
    MUTATION_SET_LOADING(state, value) {
        state.loading = value;
    },
    MUTATION_SET_LOADING_DOWNLOAD(state, value) {
        state.loadingDownload = value;
    },
    MUTATION_SET_LOADING_ADD_FILE(state, value) {
        state.loadingAddFile = value;
    },
    MUTATION_SET_CALENDAR_FILES_DOCUMENT_NEW(state, data) {
        state.calendarFilesDocumentNew = data;
    },
    MUTATION_SET_CALENDAR_FILES_DOCUMENT(state, data) {
        state.calendarFilesDocument = data;
    },
    MUTATION_DELETE_EVIDENCES(state, value) {
        state.calendarFilesDocument.splice(_.findIndex(state.calendarFilesDocument, function (o) { return o.id == value }), 1)
    },
    MUTATION_PUSH_EVIDENCES(state, value) {
        _.forEach(value, function (item) {
            state.calendarFilesDocument.push(item)
        });
    },
    MUTATION_SET_FLAG_DIALOG(state, value) {
        state.flagDialog = value;
    },
    CLEAR_STATE(state,value){
        state.calendarFiles = value; 
        state.events = value;
    }
}
const actions = {
    getDocuments({ commit,dispatch, rootState }) {
        return new Promise((resolve) => {
            commit('MUTATION_SET_LOADING', true);
            api.getDocuments().then(function (res) {
                commit('MUTATION_SET_CALENDAR_FILES', { data: res.data, root: rootState });
                commit('MUTATION_SET_EVENTS', { data: res.data, root: rootState });
                commit('MUTATION_SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                commit('MUTATION_SET_LOADING', false);
                dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e }, { root: true });

            })
        })
    },
    downloadFile(context, data) {    
        return new Promise((resolve) => {
            context.commit('MUTATION_SET_LOADING_DOWNLOAD', true);
            apiGoogle.download(data.name).then(function (res) {
                downloadFile(res.data, data.name_file);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Archivo descargado' }, { root: true });
                context.commit('MUTATION_SET_LOADING_DOWNLOAD', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_SET_LOADING_DOWNLOAD', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    store({ commit, dispatch, rootState }, data) {
        return new Promise((resolve) => {
            commit('MUTATION_SET_LOADING', true);
            api.store(data).then(function (res) {
                commit('MUTATION_SET_CALENDAR_FILES', { data: res.data, root: rootState });
                commit('MUTATION_SET_EVENTS', { data: res.data, root: rootState });
                commit('MUTATION_SET_LOADING', false);
                dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Documento creado correctamente' }, { root: true });
                resolve(res.data);
            }).catch((e) => {
                commit('MUTATION_SET_LOADING', false);
                dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    update({ commit, dispatch, rootState }, obj) {
        return new Promise((resolve) => {
            commit('MUTATION_SET_LOADING', true);
            api.update(obj.id, obj.data).then(function (res) {
                commit('MUTATION_SET_CALENDAR_FILES', { data: res.data, root: rootState });
                commit('MUTATION_SET_EVENTS', { data: res.data, root: rootState });
                commit('MUTATION_SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                commit('MUTATION_SET_LOADING', false);
                dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e }, { root: true });

            })
        })
    },
    delete({ commit, rootState, dispatch }, id) {
        return new Promise((resolve) => {
            commit('MUTATION_SET_LOADING', true);
            api.deleteCalendarDocument(id).then(function (res) {
                commit('MUTATION_SET_CALENDAR_FILES', { data: res.data, root: rootState });
                commit('MUTATION_SET_EVENTS', { data: res.data, root: rootState });
                commit('MUTATION_SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                commit('MUTATION_SET_LOADING', false);
                dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    clearState(context,data){
        context.commit('CLEAR_STATE', data);
    },
    setCalendarFileDocumentNew(context,data){
        context.commit('MUTATION_SET_CALENDAR_FILES_DOCUMENT_NEW', data);
    },
    setCalendarFileDocument(context,data){
        context.commit('MUTATION_SET_CALENDAR_FILES_DOCUMENT', data);
    },
    setFlagDialog(context,value){
        context.commit('MUTATION_SET_FLAG_DIALOG', value);
    },
    updateEvidences(context, obj) {
        return new Promise((resolve) => {
            apiEvidences.update(obj.id, obj.data).then(function (res) {
                context.commit('MUTATION_DELETE_EVIDENCES', res.data.id);
                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Documento eliminado' }, { root: true });
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    addFile(context, data) {
        return new Promise((resolve) => {
            context.commit('MUTATION_SET_LOADING_ADD_FILE', true);
            api.addFile(data).then(function (res) {
                context.commit('MUTATION_PUSH_EVIDENCES', res.data);
                context.commit('MUTATION_SET_LOADING_ADD_FILE', false);

                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Documento eliminado' }, { root: true });
            }).catch((e) => {
                context.commit('MUTATION_SET_LOADING_ADD_FILE', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
}

const getters = {
    validFile(state) {
        return state.calendarFilesDocumentNew.length
    },
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}