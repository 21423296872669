const namespaced = true
import api from '../../../../api/sap/invoice/customer_sap_report.js';
import _ from 'lodash';

const state = {
    flag_invoice_info: false,
    invoices_info: [],
    reportTypeDocument: [
        { id: 2, description: 'Factura de venta NMH' },
    ],
    reportTypeDocumentSelect: null,
    documentSelect: null

}

const mutations = {
    MUTATION_SET_FLAG_INVOICE_INFO(state, val) {
        state.flag_invoice_info = val;
    },
    MUTATION_SET_INVOICE_INFO(state, data) {
        state.invoices_info = data;
    },
    MUTATION_REPORT_TYPE_DOCUMENT_SELECT(state, value) {
        state.reportTypeDocumentSelect = value;
    },
    MUTATION_DOCUMENT_SELECT(state, value) {
        state.documentSelect = value;
    },
    ClEAR_DATA(state) {
        state.invoices_info = [];
    },
    RESET_DATA(state) {
        state.reportTypeDocumentSelect =null;
        state.documentSelect = null;
    }


}
const actions = {

    getIDataByInvoice(context, data) {
        context.commit('MUTATION_SET_FLAG_INVOICE_INFO', true);
        return new Promise((resolve) => {
            api.getIDataByInvoice(data).then(function (res) {
                context.commit('MUTATION_SET_INVOICE_INFO', res.data);
                context.commit('MUTATION_SET_FLAG_INVOICE_INFO', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_SET_FLAG_INVOICE_INFO', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })

    },
    setReportTypeDocumentSelect(context, value) {
        context.commit('MUTATION_REPORT_TYPE_DOCUMENT_SELECT', value);
        context.commit('ClEAR_DATA');
    },
    setDocumentSelect(context, value) {
        context.commit('MUTATION_DOCUMENT_SELECT', value);
        context.commit('ClEAR_DATA');
    },
    clear(context) {
        context.commit('ClEAR_DATA');
    },
    reset(context) {
        context.commit('RESET_DATA');
    }
}

const getters = {
    getInvoice(state) {
        return _.get(state.invoices_info, 'invoice', []);
    },
    getCreditNote(state) {
        return _.get(state.invoices_info, 'creditNote', []);

    },
    getOrder(state) {
        return _.get(state.invoices_info, 'order', []);
    },
    getDelivery(state) {
        return _.get(state.invoices_info, 'delivery', []);
    },
    getInvoiceNumATCard(state) {
        return { purchase_order: _.map(_.get(state.invoices_info, 'invoice'), 'NumATCard') }
    }

}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}