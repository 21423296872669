
let prefix = '/api/googleDrive';
import _ from 'lodash';


function download(data) {
    return window.axios.get(prefix + '/download/' + data, { responseType: 'arraybuffer' });
}

function getEvidences(entity_id , entity_type) {
    return window.axios.get(prefix + '/evidences/' + entity_id + '/' + entity_type);
}

function StoreEvidences(entity_id , entity_type , evidence_type, files) {
    let formData = new FormData()

    formData.append('data[0][name]', 'NUTRIMENTOS MINERALES SA DE CV');
    formData.append('data[0][idParent]', '');
    formData.append('data[0][file][name]', entity_type);
    formData.append('data[0][file][id]',entity_type);
    formData.append('data[0][file][file][name]', entity_id);
    formData.append('data[0][file][file][id]', entity_id);
    formData.append('data[0][file][file][file][name]', evidence_type);
    formData.append('data[0][file][file][file][id]', evidence_type);
    formData.append('data[0][file][file][file][file]', []);

    _.forEach(files, file => {
        formData.append('document[]', file);
    })
    
    return window.axios.post(prefix + '/storeEvidence',formData);
}

export default {
     download,getEvidences,StoreEvidences

}