let prefix = "/api/calendarFiles";
import _ from "lodash";

function getDocuments() {
    return window.axios.get(prefix + "/index");
}

function download(data) {
    return window.axios.get(prefix + "/download/" + data, {
        responseType: "arraybuffer",
    });
}

function store(data) {
    let formData = new FormData();
    formData.append("reference", data.reference);
    formData.append("company_id", data.company.id);
    formData.append("name", data.reference);
    formData.append("initial_date", data.initial_date);
    formData.append("final_date", data.final_date);
    formData.append("calendar_group_id", data.group.id);
    _.forEach(data.assign, function (user, key) {
        formData.append("assign[" + key + "][user_id]", user.id);
        formData.append("assign[" + key + "][status]", 1);
    });
    formData.append("days_to_notify", data.days_to_notify);
    formData.append("name_file", data.name_file);
    formData.append("renewal_cost", data.renewal_cost);
    formData.append("plant_id", data.location.id);
    formData.append("status", "Activo");
    formData.append("currency_type", data.currency_type);

    /*structure directory google drive*/
    formData.append("data[0][name]", "Nutrimentos");
    formData.append("data[0][idParent]", "");
    formData.append("data[0][file][name]", data.company.name);
    formData.append("data[0][file][id]", data.company.id);
    formData.append("data[0][file][file][name]", "Calendario de licencias");
    formData.append("data[0][file][file][id]", 1);
    formData.append("data[0][file][file][file]", []);

    _.forEach(data.evidences, (file) => {
        formData.append("document[]", file);
    });
    return window.axios.post(prefix + "/store", formData, {
        "Content-Type": "multipart/form-data",
    });
}

function update(id, data) {
    let assignData = [];
    _.forEach(data.assignAnt, function (user) {
        assignData.push({
            user_id: user.id,
            status: 0,
        });
       
    });
    _.forEach(data.assign, function (user) {
        assignData.push({
            user_id: user.id,
            status: 1,
        });
    });
    data.assign = assignData;
    return window.axios.post(prefix + "/update/" + id, data);
}

function deleteCalendarDocument(id) {
    return window.axios.patch(prefix + "/delete/" + id);
}

function addFile(data) {
    let formData = new FormData();
    formData.append("plantId", data.company.id);
    formData.append("documentId", data.documentId);
    /*structure directory google drive*/
    formData.append("data[0][name]", "Nutrimentos");
    formData.append("data[0][idParent]", "");
    formData.append("data[0][file][name]", data.company.name);
    formData.append("data[0][file][id]", data.company.id);
    formData.append("data[0][file][file][name]", "Calendario de licencias");
    formData.append("data[0][file][file][id]", 1);
    formData.append("data[0][file][file][file]", []);
    _.forEach(data.evidences, (file) => {
        formData.append("document[]", file);
    });
    return window.axios.post(prefix + "/addFile", formData, {
        "Content-Type": "multipart/form-data",
    });
}

export default {
    getDocuments,
    download,
    store,
    update,
    deleteCalendarDocument,
    addFile,
};
