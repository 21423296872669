
let prefix = '/api/activityLogs';

function getActivityLog(){
    return window.axios.get(prefix+'/index');
}
function storeActivityLog(data){
    return window.axios.post(prefix+'/store', data);
}

export default {
    getActivityLog,storeActivityLog
}