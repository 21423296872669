const namespaced = true

import api from '../../../api/sap/projects_sap.js';

const state = {
    projects_sap: [],
}
const mutations = {
    MUTATION_SET_PROJECTS_SAP(state, data){
         state.projects_sap = data;
    }
}
const actions = {
    getProjects(context) {
        api.getProjects().then(function (res) {
            context.commit('MUTATION_SET_PROJECTS_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}