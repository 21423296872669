const namespaced = true
import api from '../../../api/sap/goods-receipt';

const state = {
    goods_receipt_sap : []
}

const mutations = {
    MUTATION_SET_GOODS_RECEIPT_SAP(state, data) {
        state.goods_receipt_sap = data;
    }
}
const actions = {
    getGoodsReceipt(context, data) {
        return new Promise((resolve) => {
            api.getGoodsReceipt(data.date1, data.date2).then(function (res) {
                context.commit('MUTATION_SET_GOODS_RECEIPT_SAP', res.data);
                resolve(res.data);
            }).catch((e) => {
                console.log(e);
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
            })
        })
    },

}

const getters = {}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}

