const namespaced = true
import _ from 'lodash';

const state = {
     activities: [],
     quickaccess : []
}
const mutations = {
     SET_ACTIVITIES(state, value) {
          let obj = _.find(state.activities, { id: value.id });
          (obj) ? state.activities.splice(state.activities.indexOf(obj), 1) : null
          state.activities.push(value);
     },
     SET_QUICKACCESS(state,data){
          state.quickaccess = data;
     }
}
const actions = {
     save(context, activities) {
          context.commit('SET_ACTIVITIES', activities)
          context.dispatch('activities/filterQuickAccess',{},{ root: true });
          context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Acceso directo registrado' }, { root: true });
     },
     filterQuickAccess({state, commit, rootGetters}){
          let tempo = _.find(state.activities, { 'id': rootGetters['configuration/getConfigurationID'] });
          commit('SET_QUICKACCESS', ((tempo) ? this.items = _.orderBy(tempo.item, 'text', 'asc') : []));          
     }
     
}

const getters = {

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
