const namespaced = true
import _ from 'lodash'
import api from '../../../../api/sap/invoice/supplier_sap.js';
import apiReport from '../../../../api/sap/invoice/supplier_sap_report.js';
import { downloadFile } from '../../../../src/helpers/util.js'
import {dayForPay} from '../../../../filters.js'

const state = {
    loading: false,
    loadingReport: false,
    invoices_supplier_sap: [],
    purchases_orders_invoices_supplier_sap: [],
    purchases_orders_invoices_supplier_sap_original: [],
    purchases_orders_invoices_supplier_sap_filter: [],
    purchases_orders_invoices_supplier_sap_flag: false,
    warehouses_selected_by_purchases_orders_sap: { WhsCode: '*', WhsName: 'Todos' },
    expenses_invoices_supplier_sap: [],
    expenses_invoices_supplier_sap_original: [],
    expenses_invoices_supplier_sap_filter: [],
    expenses_invoices_supplier_sap_flag: false,
    projects_selected_by_expenses_sap: { PrjCode: '*', PrjName: 'Todos' },
    showReportZoho: false,
    value : '',
    invoicesReport: [],
    mapRelation: null,
    searchSelect: '',
    docCurSelect: 'MXN',
    docTotalSelect: 1,
    dateInitialSelect: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split("T")[0],
    dateFinalSelect: new Date(new Date().getFullYear(), new Date().getMonth(), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()).toISOString().split("T")[0],
    invoiceSelect: null,
    searchSelectBatch: '',
    invoice_supplier_sap: [],
    invoices_supplier_sap_base_ref: [],
}

const mutations = {
    SET_LOADING(state, val) {
        state.loading = val;
    },
    MUTATION_SET_INVOICES_SUPPLIER_SAP(state, data) {
        state.invoices_supplier_sap = data;
    },
    MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP(state, data) {
        state.purchases_orders_invoices_supplier_sap = data;
        state.purchases_orders_invoices_supplier_sap_original = data
        state.purchases_orders_invoices_supplier_sap_filter = data
    },
    MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP_FLAG(state, value) {
        state.purchases_orders_invoices_supplier_sap_flag = value;
    },
    MUTATION_SET_WAREHOUSES_SELECT__BY_PURCHASES_ORDERS_SAP(state, value) {
        state.warehouses_selected_by_purchases_orders_sap = value;
    },
    MUTATION_SET_FILTERS(state) {
        state.purchases_orders_invoices_supplier_sap = state.purchases_orders_invoices_supplier_sap_original;
        state.purchases_orders_invoices_supplier_sap = (state.warehouses_selected_by_purchases_orders_sap.WhsCode == '*') ?
        state.purchases_orders_invoices_supplier_sap : _.filter(state.purchases_orders_invoices_supplier_sap, { 'WhsCode': 
        state.warehouses_selected_by_purchases_orders_sap.WhsCode });
    },
    MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP_FILTER(state, value) {
        state.purchases_orders_invoices_supplier_sap_filter = value;
    },
    MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP(state, data) {
        state.expenses_invoices_supplier_sap = data;
        state.expenses_invoices_supplier_sap_original = data
        state.expenses_invoices_supplier_sap_filter = data
    },
    MUTATION_SET_PROJECTS_SELECT__BY_EXPENSES_SAP(state, value) {
        state.projects_selected_by_expenses_sap = value;
    },
    MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP_FLAG(state, value) {
        state.expenses_invoices_supplier_sap_flag = value;
    },
    MUTATION_SET_FILTERS_EXPENSES(state) {
        state.expenses_invoices_supplier_sap = state.expenses_invoices_supplier_sap_original;
        state.expenses_invoices_supplier_sap = (state.projects_selected_by_expenses_sap.PrjCode == '*') ? state.expenses_invoices_supplier_sap : _.filter(state.expenses_invoices_supplier_sap, { 'Project': state.projects_selected_by_expenses_sap.PrjCode });
    },
    MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP_FILTER(state, value) {
        state.expenses_invoices_supplier_sap_filter = value;
    },
    MUTATION_SET_SHOW_REPORT_ZOHO(state, value) {
        state.showReportZoho = value;
    },
    MUTATION_SET_VALUE(state, value) {
        state.value = value;
    },
    MUTATION_SET_RESET_INVOICES(state) {
        state.invoicesReport = [];
        state.invoices_supplier_sap = [];
    },
    MUTATION_SET_MAP_RELATION(state, data) {
        state.mapRelation = data;
    },
    MUTATION_SET_SEARCH_SELECT(state, value) {
        state.searchSelect = value;
    },
    MUTATION_SET_SEARCH_SELECT_BATCH(state, value) {
        state.searchSelectBatch = value;
    },
    MUTATION_SET_DOC_CUR(state, value) {
        state.docCurSelect = value;
    },
    MUTATION_SET_DOC_TOTAL_SELECT(state, value) {
        state.docTotalSelect = value;
    },
    MUTATION_SET_DATE_INI(state, value) {
        state.dateInitialSelect = value;
    },
    MUTATION_SET_DATE_FIN(state, value) {
        state.dateFinalSelect = value;
    },
    MUTATION_SET_INVOICE_SELECT(state, data) {
        state.invoiceSelect = data;
    },
    MUTATION_SET_SUPPLIER_SAP(state, data) {
        state.invoice_supplier_sap = data;
    },
    MUTATION_SET_SUPPLIER_SAP_base_ref(state, data) {
        state.invoices_supplier_sap_base_ref = data;
    },
}
const actions = {
    setSearchSelect(context, value) {
        context.commit('MUTATION_SET_SEARCH_SELECT', value);
    },
    setSearchSelectBatch(context, value) {
        context.commit('MUTATION_SET_SEARCH_SELECT_BATCH', value);
    },
    setDocCur(context, value) {
        context.commit('MUTATION_SET_DOC_CUR', value);
    },
    setDocTotal(context, value) {
        context.commit('MUTATION_SET_DOC_TOTAL_SELECT', value);
    },
    setDateIni(context, value) {
        context.commit('MUTATION_SET_DATE_INI', value);
    },
    setDateFin(context, value) {
        context.commit('MUTATION_SET_DATE_FIN', value);
    },
    setInvoice(context, data) {
        context.commit('MUTATION_SET_INVOICE_SELECT', data);
    },
    getAuthInvoices(context, data) {
        var x = [];
        context.commit('SET_LOADING', true);
        return new Promise((resolve, reject) => {
            api.getAuthInvoices(data.date1, data.date2, data.cardCode).then(resp => {
                resolve(resp)
            }).catch((error) => {
                reject(error)
            })
        }).then(function (resp) {
            var tmp = [];
            x['data'] = _.groupBy(resp.data, 'DocEntry');
            for (var i in x['data']) {
                tmp.push(x['data'][i]);
            }
            context.dispatch('finance/setUniverse', tmp, { root: true });
            context.commit('MUTATION_SET_INVOICES_SUPPLIER_SAP', resp.data);
            context.commit('SET_LOADING', false);

        }).catch((error) => {
            context.commit('MUTATION_SET_INVOICES_SUPPLIER_SAP',[]);
            context.commit('SET_LOADING', false);
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
        })
    },
    getInvoicesSupplier(context, data) {
        api.getInvoicesSupplier(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_INVOICES_SUPPLIER_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        })
    },
    getGeneralPurchases(context, data) {
        context.commit('MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP_FLAG', true);
        context.commit('MUTATION_SET_VALUE','');
        api.getGenaralPurchases(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP', res.data);
            context.dispatch('Filters');
            context.commit('MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP_FLAG', false);
        }).catch((e) => {
            context.commit('MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP_FLAG', false);
            context.commit('MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP', []);
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        })
    },
    setWarehouseSelectGeneralPurchasesSap(context, value) {
        context.commit('MUTATION_SET_WAREHOUSES_SELECT__BY_PURCHASES_ORDERS_SAP', value);
        context.dispatch('Filters');
    },
    Filters(context) {
        context.commit('MUTATION_SET_FILTERS');
    },
    OrdersInvoicesSupplierFilter(context, value) {
        context.commit('MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP_FILTER', value);
    },
    getExpenses(context, data) {
        context.commit('MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP_FLAG', true);
        context.commit('MUTATION_SET_VALUE','');
        api.getExpenses(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP', res.data);
            context.dispatch('FiltersExpenses');
            context.commit('MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP_FLAG', false);
        }).catch((e) => {
            context.commit('MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP_FLAG', false);
            context.commit('MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP', []);
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        })
    },
    setProjectSelectExpensesSap(context, value) {
        context.commit('MUTATION_SET_PROJECTS_SELECT__BY_EXPENSES_SAP', value);
        context.dispatch('FiltersExpenses');
    },
    FiltersExpenses(context) {
        context.commit('MUTATION_SET_FILTERS_EXPENSES');
    },
    ExpensesInvoicesSupplierFilter(context, value) {
        context.commit('MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP_FILTER', value);
    },
    SetShowReportZoho(context, value) {
        context.commit('MUTATION_SET_SHOW_REPORT_ZOHO', value);
    },
    SetValue(context, value) {
        context.commit('MUTATION_SET_VALUE', value);
    },
    downloadExcel(context, data) {
        context.commit('MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP_FLAG', true);
        return new Promise((resolve) => {
            api.downloadExcel(data.date1, data.date2).then(async function (res) {
                downloadFile(res.data, 'Proeco - Compras Generales de '+data.date1+' al '+data.date2+'.xlsx');
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Documento descargado' }, { root: true });
                context.commit('MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP_FLAG', false);
                resolve(res.data);
            }).catch((error) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
                context.commit('MUTATION_SET_PURCHASES_ORDERS_INVOICES_SUPPLIER_SAP_FLAG', false);
            })
        })
    },
    downloadExcelExpense(context, data) {
        context.commit('MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP_FLAG', true);
        return new Promise((resolve) => {
            api.downloadExcelExpense(data.project, data.date1, data.date2).then(async function (res) {
                downloadFile(res.data, data.project+' - Gastos de '+data.date1+' al '+data.date2+'.xlsx');
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Documento descargado' }, { root: true });
                context.commit('MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP_FLAG', false);
                resolve(res.data);
            }).catch((error) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });
                context.commit('MUTATION_SET_EXPENSES_INVOICES_SUPPLIER_SAP_FLAG', false);
            })
        })
    },
    getDevolucionIva(context, data) {
        context.commit('SET_LOADING_REPORT', true);
        api.getDevolucionIva(data.date1, data.date2, data.DocCur, data.cardName).then(function (res) {
            context.commit('MUTATION_SET_INVOICES_REPORT', res.data);
            context.commit('SET_LOADING_REPORT', false);
        }).catch((e) => {
            context.commit('MUTATION_SET_INVOICES_REPORT', []);
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            context.commit('SET_LOADING_REPORT', false);
        })
    },
    getDevolucionIvaExport(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING_REPORT', true);
            api.getDevolucionIvaExport(data.data).then(function (res) {
                downloadFile(res.data, data.name);
                context.commit('SET_LOADING_REPORT', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING_REPORT', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })

    },
    resetInvoices(context) {
        context.commit('MUTATION_SET_RESET_INVOICES');
    },
    mapRelation(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING_REPORT', true);
            context.dispatch('payment_outgoing_sap/setClear', {}, { root: true });
            apiReport.mapRelation(data).then(function (res) {
                context.commit('MUTATION_SET_MAP_RELATION', res.data);
                context.commit('SET_LOADING_REPORT', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING_REPORT', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })

    },
    clearData(context) {
        context.commit('MUTATION_SET_SEARCH_SELECT', '');
        context.commit('MUTATION_SET_DOC_CUR', 'MXN');
        context.commit('MUTATION_SET_DOC_TOTAL_SELECT', 1);
        context.commit('MUTATION_SET_DATE_INI', new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split("T")[0]);
        context.commit('MUTATION_SET_DATE_FIN', new Date(new Date().getFullYear(), new Date().getMonth(), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()).toISOString().split("T")[0]);
        context.commit('MUTATION_SET_SEARCH_SELECT_BATCH', '');
        context.commit('MUTATION_SET_SUPPLIER_SAP',[]);
        context.commit('MUTATION_SET_SUPPLIER_SAP_base_ref',[]);
    },
    setDateIniSNBatch(context, value) {
        context.commit('MUTATION_SET_DATE_INI', value);
    },
    setDateFinSNBatch(context, value) {
        context.commit('MUTATION_SET_DATE_FIN', value);
    },
    getInvoicesSupplierShow(context, docEntry) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING_REPORT', true);
            api.getInvoicesSupplierShow(docEntry).then(function (res) {
                context.commit('MUTATION_SET_SUPPLIER_SAP', res.data);
                context.commit('SET_LOADING_REPORT', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING_REPORT', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })
        
    },
    getInvoicesSupplierInvoices(context, docEntry) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING_REPORT', true);
            api.getReportPurchasesInvoices(docEntry).then(function (res) {
                context.commit('MUTATION_SET_SUPPLIER_SAP_base_ref', res.data);
                context.commit('SET_LOADING_REPORT', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING_REPORT', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })
        
    },
}

const getters = {
    getWarehouses: (state, getter, rootState) => {
        return _.uniq(state.purchases_orders_invoices_supplier_sap_original.map(item =>
            _.find(rootState.warehouses_sap.warehouses_sap, { 'WhsCode': item.WhsCode })
        ));
    },
    getDataTableExport(state) {
        let transformData = _.map(state.invoicesReport, item => [
            item.DocNum,
            item.CardName,
            item.DocTotal,
            item.DocCur,
            item.DocDate,

        ]);
        return transformData;
    },
    getDataTableExportHistoryInvoice(state) {
        let transformData = _.map(state.invoicesReport, item => [
            item.DocNum,
            item.NumATCard,
            item.CardName,
            item.DocTotal - item.VatSum,
            item.VatSum,
            item.WTSum,
            item.DocTotal - item.WTSum,
            item.DocCur,
            item.DocDate,

        ]);
        return transformData;
    },
    getMapRelationInvoice(state) {
        return (state.mapRelation != null) ? state.mapRelation.OPCH : []
    },
    getMapRelationNc(state) {
        return (state.mapRelation != null) ? state.mapRelation.ORPC : []
    },
    getMapRelationReceiptNum(state) {
        return (state.mapRelation != null) ? state.mapRelation.OPCH[0].ReceiptNum : null
    },
    getDocTotal(state) {
        return (state.mapRelation != null) ? parseFloat(state.mapRelation.OPCH[0].DocTotal) : 0
    },
    getDocVatSum(state) {
        return (state.mapRelation != null) ? parseFloat(state.mapRelation.OPCH[0].VatSum) : 0
    },
    getDocWTSum(state) {
        return (state.mapRelation != null) ? parseFloat(state.mapRelation.OPCH[0].WTSum) : 0
    },
    getDocSubtotal(state) {
        return (state.mapRelation != null) ? parseFloat(state.mapRelation.OPCH[0].Subtotal) : 0
    },
    getCurrency(state) {
        return (state.mapRelation != null) ? parseFloat(state.mapRelation.OPCH[0].DocCur) : 0
    },
    getDocDiscSum(state) {
        return (state.mapRelation != null) ? parseFloat(state.mapRelation.OPCH[0].DiscSum) : 0
    },
    getDocTotalSum(state) {
        return _.sumBy(state.invoicesReport, function (o) { return parseFloat(o.DocTotal); });
    },
    getIvaSum(state) {
        return _.sumBy(state.invoicesReport, function (o) { return parseFloat(o.VatSum); });
    },
    getSubTotalSum(state) {
        return _.sumBy(state.invoicesReport, function (o) { return parseFloat(o.DocTotal); }) - _.sumBy(state.invoicesReport, function (o) { return parseFloat(o.VatSum); });
    },
    getCountInvoice(state) {
        return state.invoicesReport.length;
    },
    getCarCode(state) {
        return (state.invoicesReport.length > 0) ? state.invoicesReport[0].CardCode : null;
    },
    dataWithTotals(state) {

        return [
            ...state.invoicesReport,
            {
                DocNum: 'Totales',
                CardName: null,
                VatSum: parseFloat(_.sumBy(state.invoicesReport, 'VatSum')),
                WTSum: parseFloat(_.sumBy(state.invoicesReport, 'WTSum')),
                SubTotal: parseFloat(_.sumBy(state.invoicesReport, 'DocTotal')) - parseFloat(_.sumBy(state.invoicesReport, 'VatSum')),
                DocTotal: parseFloat(_.sumBy(state.invoicesReport, 'DocTotal')),
                DocCur: null,
                DocDate: null,


            },
        ];
    },
    getDataTableInvoices(state) {
        return (state.invoices_supplier_sap.length > 0) ? _.uniqBy(state.invoices_supplier_sap, 'DocEntry') : []
    },
    getDataTableInvoicesExport(state, getter) {
        let transformData = _.map(getter.getDataTableInvoices, item => [
            item.DocNum,
            item.NumATCard,
            item.CardName,
            item.DocTotal - item.VatSum,
            item.VatSum,
            item.WTSum,
            item.DocTotal,
            item.DocCur,
            item.DocDate,
            item.DocDueDate,
            dayForPay(item.DocDueDate)

        ]);
        return transformData;
    },
    getProjects: (state, getter, rootState) => {
        return _.uniq(state.expenses_invoices_supplier_sap_original.map(item =>
            _.find(rootState.projects_sap.projects_sap, { 'PrjCode': item.Project })
        ));
    },
    getItemsTable: (state) => {
        return state.purchases_orders_invoices_supplier_sap;
    },
    getPagoPendiente: (state, getter) => {
        return _.sumBy(getter.getItemsTableFilter, 'PendingPayment');
    },
    getPagoTotal: (state, getter) => {
        return _.sumBy(getter.getItemsTableFilter, 'DocTotal');
    },
    getPagoPagado: (state, getter) => {
        return _.sumBy(getter.getItemsTableFilter, 'Payment.DocTotal');
    },
    getItemsTableFilter: (state) => {
        return state.purchases_orders_invoices_supplier_sap.filter(item => {
            return (
                item.DocDate.toLowerCase().includes(state.value.toLowerCase()) ||
                item.DocTotal >= state.value && item.DocTotal <= state.value ||
                item.PendingPayment >= state.value && item.PendingPayment <= state.value ||
                item.Payment.DocTotal >= state.value && item.Payment.DocTotal <= state.value ||
                item.CardName.toLowerCase().includes(state.value.toLowerCase()) ||
                item.Lines.some(line => {
                    return (
                        line.ItemCode.toLowerCase().includes(state.value.toLowerCase()) ||
                        line.ItemName.toLowerCase().includes(state.value.toLowerCase())
                    );
                })
                 ||item.DocEntry.toLowerCase().includes(state.value.toLowerCase())

            );
        });
    },
    getExpensesTableFilter: (state) => {
        return state.expenses_invoices_supplier_sap.filter(item => {
            return (
                item.DocNum.toLowerCase().includes(state.value.toLowerCase()) ||
                item.CardCode.toLowerCase().includes(state.value.toLowerCase()) ||
                item.CardName.toLowerCase().includes(state.value.toLowerCase()) ||
                item.ItemCode.toLowerCase().includes(state.value.toLowerCase()) ||
                item.Dscription.toLowerCase().includes(state.value.toLowerCase()) ||
                item.Quantity >= state.value && item.Quantity <= state.value ||
                item.UDM.toLowerCase().includes(state.value.toLowerCase()) ||
                item.ItmsGrpNam.toLowerCase().includes(state.value.toLowerCase()) ||
                item.Price >= state.value && item.Price <= state.value ||
                item.SubTotal >= state.value && item.SubTotal <= state.value ||
                item.Iva >= state.value && item.Iva <= state.value ||
                item.Total >= state.value && item.Total <= state.value ||
                item.Payment >= state.value && item.Payment <= state.value ||
                item.Project.toLowerCase().includes(state.value.toLowerCase()) ||
                item.WhsCode.toLowerCase().includes(state.value.toLowerCase()) ||
                item.WhsName.toLowerCase().includes(state.value.toLowerCase())
            );
        });
    }
}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}