import _ from 'lodash';
const namespaced = true;

export const state = {
    themeDark: false,
    userAuth: {
        id: '',
        name: '',
        email: null,
        abilities: [],
        roles: [],
        slp_code: '',
        emp_id: '',
        whs: []
    },
}

const mutations = {
    SET_CONFIGURATION_USER_AUTH(state, value) {
        state.userAuth = value;
        state.isLoggedIn = (value.id) ? true : false;
    },
    SET_CONFIGURATION(state, input_data) {
        state.configuration = input_data;
    },
    SET_THEME_MODE(state, input_data) {
        state.themeDark = input_data;
    },
}

const actions = {
    resetConfiguration(context) {
        context.commit('SET_CONFIGURATION', { themeDark: false });
    },
    setDarkMode(context, value) {
        if (value) {
            context.commit('SET_THEME_MODE', false);
        } else {
            context.commit('SET_THEME_MODE', true);
        }
    },
    setUserAuth(context, value) {
        context.commit('SET_CONFIGURATION_USER_AUTH', value);
    }

}

const getters = {
    getConfigurationID(state) {
        return state.userAuth.id
    },
    getConfigurationName(state) {
        return state.userAuth.name
    },
    getConfigurationEmail(state) {
        return state.userAuth.email
    },
    getAbilitiesUser(state) {
        return state.userAuth.abilities;
    },
    getStringRolesUser(state) {
        return _.toString(_.map(state.userAuth.roles, role => role.title));
    },
    getSlpCode(state) {
        return state.userAuth.slp_code;
    },
    getUserAuth(state) {
        return state.userAuth;
    },
    getEmpId(state) {
        return state.userAuth.emp_id;
    },
    getWhsAssign(state) {
        let temp = [];
        temp.push({ whs_code: 'FC', whs_name: 'Celaya' });
        temp.push({ whs_code: 'NM', whs_name: 'Tizayuca' });
        temp.push({ whs_code: '*', whs_name: 'Todos' });
        return _.concat(temp,  _.map(state.userAuth.whs, obj => _.pick(obj, ['whs_code', 'whs_name'])));
    },

}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}