const namespaced = true

import api from '../../../api/sap/business_partner.js';

const state = {
    businessPartner: null,
    businessPartners : [],
}
const mutations = {
    MUTATION_SET_BUSINESS_PARTNER(state, data){
         state.businessPartner = data;
    },
    MUTATION_SET_BUSINESS_PARTNERS(state, data){
         state.businessPartners = data;
    }
}
const actions = {
    getBusinessPartner(context,data) {
        api.search(data).then(function (res) {
            context.commit('MUTATION_SET_BUSINESS_PARTNER', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    getByCardType(context,data) {
        api.showByCardType(data).then(function (res) {
            context.commit('MUTATION_SET_BUSINESS_PARTNERS', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
}

const getters = {
    getBusinessPartnerRfC(state) {
        return (state.businessPartner != null) ? state.businessPartner[0].LicTradNum : null
    },
    getBusinessPartnerBalance(state) {
        return (state.businessPartner != null) ? state.businessPartner[0].Balance : null
    },
    getBusinessPartnerCardCode(state) {
        return (state.businessPartner != null) ? state.businessPartner[0].CardCode : null
    },
    getBusinessPartnerCardName(state) {
        return (state.businessPartner != null) ? state.businessPartner[0].CardName : null
    },
    getBusinessPartnerCurrency(state) {
        return (state.businessPartner != null) ? state.businessPartner[0].Currency : null
    },
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}