const namespaced = true
import { downloadFile } from '../../src/helpers/util.js'
import api from '../../api/sales/clientPortfolio.js';

const state = {
     
}
const mutations = {

}
const actions = {
    exportDocument(context) {
        return new Promise((resolve, reject) => {
            // context.commit("MUTATION_SET_LOADING_DOWNLOAD", true);
            api.exportDocument()
                .then(function (res) {
                    const today = new Date();
                    const society = 'PROECO';
                    const fileName = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}_cartera-clientes_${society}.xlsx`;
                    downloadFile(res.data, fileName);
                    // context.commit("MUTATION_SET_LOADING_DOWNLOAD", false);
                    resolve(res.data);
                })
                .catch((e) => {
                    // context.commit("MUTATION_SET_LOADING_DOWNLOAD", false);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: e.messages,
                        },
                        { root: true }
                    );
                    reject();
                });
        });
    },
     
}

const getters = {
    
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
