let prefix = '/api/role';

function getRoles() {
    return window.axios.get(prefix+'/index');
}
function store(data) {
    return window.axios.post(prefix+'/store', data)
}
function update(id, data) {
    return window.axios.post(prefix+'/'+id+'/update', data);
}
function deleted(id){
    return window.axios.post(prefix+'/'+id+'/delete');
}
//Permitir/NoPermitor de Permisos a Roles
function allowAbilityToRol(role, ability){
    return window.axios.post(prefix+'/allow-ability-to-rol/'+role+'/'+ability);
}
function disallowAbilityToRol(role, ability){
    return window.axios.post(prefix+'/disallow-ability-to-rol/'+role+'/'+ability);
}
export default {
    getRoles,
    store,
    update,
    deleted,
    //Permitir/NoPermitor de Permisos a Roles
    allowAbilityToRol,
    disallowAbilityToRol
}