/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
/* Filter*/

import { number } from './filters.js';
import { date } from './filters.js';
import { dateFormat } from './filters.js';
import { AuthorizationFormat } from './filters.js';
import { timeFormat } from './filters.js';
import { statusString } from './filters.js';
import { dataFormatWord } from './filters.js';
import {kbbtsToMbs} from './filters.js';
import { sapCodeToText } from './filters.js';
import { dayForPay } from './filters.js';


/**
 *  Lodash
 */
import _ from 'lodash';

/**
 *  Vue Instance
 */
import Vue from 'vue';

window.Vue = Vue;
window.Bus = new Vue();

/**
 *   Container
 */
import App from './components/App.vue';

/**
 *  Vue Router
 */
import router from './routes/index.js';

/**
 *  Vuex
 */
import store from './store/index.js';

/**
 *  Vuetify and Theme Configuration
 */
import vuetify from './plugins/vuetify.js';

/**
 *  Vee-Validate   
 */
import "./vee-validate";
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import * as messages from 'vee-validate/dist/locale/es.json';
/**
 * Vue-preview
 */
import VuePreview from 'vue-preview'

_.forEach(Object.keys(rules), rule => {
    extend(rule, { ...rules[rule], message: messages[rule] });
});

extend('between', {
    message: 'Información no valida.'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

/**
 *  Abilities
 */
import abilities from './mixin/abilities';
Vue.mixin(abilities);

/**
 *  Interceptor
 */
import InterceptorsSetup from './src/helpers/interceptors';

/**
 *  GoogleMaps
 */
//  import * as VueGoogleMaps from 'vue2-google-maps';

//  Vue.use(VueGoogleMaps, {
//     load: {
//     key: '',
//     libraries: 'places',
//     }
// });

/**
 * Firebase configuration.
 */
// import firebase from 'firebase/app';
//  const config = {
//      apiKey: "",
//      authDomain: "",
//      projectId: "",
//      storageBucket: "",
//      messagingSenderId: "",
//      appId: "",
//      measurementId: ""
//    };

//  firebase.initializeApp(config);

Vue.config.productionTip = false;
let app = '';

import VuePictureSwipe from 'vue-picture-swipe';
Vue.component('vue-picture-swipe', VuePictureSwipe);

//  firebase.auth().onAuthStateChanged(() => {
if (!app) {
    InterceptorsSetup();
    app = new Vue({
        router,
        store,
        vuetify,
        ValidationProvider,
        VuePreview,
        VuePictureSwipe,
        render: h => h(App)
    }).$mount('#app');
}
// });

Vue.filter('date', date);
Vue.filter('number', number);
Vue.filter('dateFormat', dateFormat);
Vue.filter('AuthorizationFormat', AuthorizationFormat);
Vue.filter('timeFormat', timeFormat);
Vue.filter('statusString', statusString);
Vue.filter('dataFormatWord', dataFormatWord);
Vue.filter('kbbtsToMbs', kbbtsToMbs);
Vue.filter('sapCodeToText', sapCodeToText);
Vue.filter('dayForPay', dayForPay);

Vue.use(VuePreview, {
    mainClass: 'pswp__share-tooltip',
    barsSize: { top: 0, bottom: 0 },
    captionEl: true,
    fullscreenEl: true,
    shareEl: true,
    zoomEl: true,
    bgOpacity: 0.85,
    tapToClose: false,
    tapToToggleControls: false,
})
