const namespaced = true
import _ from 'lodash'
import api from '../../../../api/sap/invoice/supplier_sap.js';

const state = {
    data: null,
    loading: false,
    loadingDownload: false,
    loadingDocument: false,
    supplierSelect: null,
    whsSelect: { whs_code: "*", whs_name: "Todos" },
    listHistory: [],
    typePrchseItem: 'Factura',
    itemSelect: null,
    purchaseOrder: null,
    purchasesReport: null,
    search: null,
    AgentSelect: [],
    //0 - Complete 1- Partial 2-Incomplete
    filterStatus: 0,
    docNumSelected: 0,
    targetTypeSelected: 0,
    targetEntrySelected: 0,
    flagDocument: false,
    flagDocumentInvoice: false,
    dateInitial: new Date(Date.now()).toISOString().substr(0, 10),
    dateFinal: new Date(Date.now()).toISOString().substr(0, 10),
    totalQuantity: 0,
    totalPrice: 0,
    flagTotal: false,
    firstItemUnitMsr: '',
    docCur: ''
}

const mutations = {

    MUTATION_SET_DATA(state, data) {
        state.data = data;
    },
    MUTATION_SET_LOADING(state, value) {
        state.loading = value;
    },
    MUTATION_SET_ARTICULE(state, data) {
        state.listHistory = data;
    },
    MUTATION_SET_TYPE(state, tipo) {
        state.typePrchseItem = tipo;
    },
    MUTATION_SET_KPI(state) {
        state.totalQuantity = _.sumBy(state.data.items, item => parseFloat(item.Quantity));
        state.totalPrice = _.sumBy(state.data.items, item => parseFloat(item.DocTotal));
        state.flagTotal = true;

        if (state.data.items.length > 0) {
            state.firstItemUnitMsr = state.data.items[0].unitMsr;
            state.docCur = state.data.items[0].DOCCUR;
        }
    },
    MUTATION_SET_ITEM_SELECT(state, value) {
        state.itemSelect = value != null ? value : null;
    },
    MUTATION_SET_SEARCH(state, value) {
        state.search = value;
    },
    MUTATION_SET_LOADING_DOCUMENT(state, value) {
        state.loadingDocument = value;
    },
    MUTATION_SET_DATE(state,data){
        state.dateInitial = data.date1;
        state.dateFinal = data.date2; 
    },
    MUTATION_SET_PURCHASE_ORDER(state, value) {
        state.purchaseOrder = value;
    },

}
const actions = {
    getByWhs(context, data) {
        context.commit("MUTATION_SET_LOADING", true);
        return new Promise((resolve) => {
            api.getInvoceByitem(
                data.page,
                data.perPage,
                data.date1,
                data.date2,
                data.item,
                data.cardCode
            )
                .then(function (res) {
                    context.commit("MUTATION_SET_DATA", res.data);
                    context.commit('MUTATION_SET_TYPE', res.data.tipo);
                    context.commit("MUTATION_SET_LOADING", false);
                    context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Se obtuvieron un Total: ' + res.data.total }, { root: true });
                    resolve(res.data);
                })
                .catch((e) => {
                    context.commit("MUTATION_SET_LOADING", false);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: e.messages,
                        },
                        { root: true }
                    );
                });
        });
    },
    clearData(context, data) {
        context.commit("MUTATION_SET_DATA", data);
        context.commit("MUTATION_SET_SUPPLIER_SELECT", "Todos");
        context.commit("MUTATION_SET_WHS_SELECT", {
            whs_code: "*",
            whs_name: "Todos",
        });
    },
    getArticule(context, data) {
        context.commit("MUTATION_SET_LOADING", true);
        let tipo = (context.state.typePrchseItem == 'Factura') ? context.state.typePrchseItem : 'orden';
        return new Promise((resolve) => {
            api.byArticule(1, 1000, tipo, data)
                .then(function (res) {
                    context.commit("MUTATION_SET_ARTICULE", res.data.items);
                    context.commit("MUTATION_SET_LOADING", false);
                    resolve(res.data);
                })
                .catch((e) => {
                    context.commit("MUTATION_SET_LOADING", false);
                    context.dispatch("notifications/add", { color: "error", visibility: true, timeout: 5000, text: e.messages, }, { root: true });
                });
        });
    },
    cleanListHystory(context) {
        context.commit("MUTATION_SET_ARTICULE", []);
    },
    getKpis(context) {
        context.commit("MUTATION_SET_KPI");
    },
    setSupplier(context, data) {
        context.commit("MUTATION_SET_SUPPLIER_SELECT", data);
    },
    setWhs(context, data) {
        context.commit("MUTATION_SET_WHS_SELECT", data);
    },
    setItem(context, data) {
        context.commit("MUTATION_SET_ITEM_SELECT", data);
    },
    setSearch(context, data) {
        context.commit("MUTATION_SET_SEARCH", data);
    },
    
    getDetailInvoce(context, data) {
        return new Promise((resolve) => {
            api.getInvoicesSupplierShow(data).then(function (res) {
                context.commit('MUTATION_SET_PURCHASE_ORDER', res.data);
                context.commit('MUTATION_SET_LOADING_DOCUMENT', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_SET_LOADING_DOCUMENT', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })
    },
    newDate(context,data){
        context.commit("MUTATION_SET_DATE", data);
    },
    
}

const getters = {
    getValidate(state) {
        return state.data != null ? state.data.items.length : 0;
    },
    getPurchasesOrder(state) {
        if (state.data != null) {
            let temp =
                state.supplierSelect == null
                    ? state.data.items
                    : _.filter(state.data.items, [
                        "CardName",
                        state.supplierSelect.CardName,
                    ]);
            if (state.whsSelect.whs_code != "*")
                temp = _.filter(temp, ["WhsCode", state.whsSelect.whs_code]);
            if (state.itemSelect != null)
                temp = _.filter(temp, ["ItemCode", state.itemSelect.ItemCode]);

            return temp;
        } else {
            return [];
        }
    },
    getAntPage(state) {
        return state.data != null ? state.data.antPage : false;
    },
    getNextPage(state) {
        return state.data != null ? state.data.nextPage : false;
    },
    getDataTableExport(state, getter) {
        let transformData = _.map(getter.getPurchasesOrder, (item) => [
            item.DocNum,
            item.DocDate,
            item.ItemCode,
            item.Dscription,
            item.CardName,
            item.WhsCode,
            item.Price,
            item.penultPreComp,
            item.penultFecComp,
        ]);
        return transformData;
    },
    getDocCur(state) {
        return state.data.items != null
            ? state.data.items[0].DocCur
            : "";
    } ,
   

    getDocNum(state) {
        return state.purchaseOrder != null ? state.purchaseOrder[0].DocNum : "";
    },
    getDate(state) {
        return state.purchaseOrder != null
            ? state.purchaseOrder[0].DocDate
            : "";
    },
    getComment(state) {
        return state.purchaseOrder != null
            ? state.purchaseOrder[0].Comments
            : "";
    },
    getDocTotal(state) {
        return state.purchaseOrder != null
            ? state.purchaseOrder[0].DocTotal
            : "";
    },

    getVatSum(state) {
        return state.purchaseOrder != null ? state.purchaseOrder[0].VatSum : "";
    },
    getSubTotal(state) {
        return state.purchaseOrder != null
            ? parseFloat(state.purchaseOrder[0].DocTotal) -
            parseFloat(state.purchaseOrder[0].VatSum)
            : "";
    },
    getReportPurchases(state) {
        return _.groupBy(
            _.orderBy(state.purchasesReport, ["DocEntry"], ["desc"]),
            "DocEntry"
        );
    },

}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}