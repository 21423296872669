const namespaced = true

import api from '../../api/batch-service.js';
import apiGoogle from '../../api/googleDrive.js';
import { downloadFile } from '../../src/helpers/util.js'
import _ from 'lodash'

const state = {
    batchWithoutPay: [],
    loading: false,
    loadingDownload: false,
    availableBatch: [],
    availableBatchTempo: [],
    documentBatchAll: {},
    availableBatchAuth: [],
    availableBatchAuthTempo: [],
    type: 'pending',
    pageBatch: [],
    payBatchObj: null,
    evidences: [],
    locationSelect: { whs_code: '*', whs_name: 'Todos' },

}
const mutations = {
    SET_LOADING(state, val) {
        state.loading = val;
    },
    SET_LOADING_DOWNLOAD(state, val) {
        state.loadingDownload = val;
    },
    SET_AVAILABLE_BATCH(state, val) {
        state.availableBatch = val;
    },
    SET_AVAILABLE_BATCH_TEMPO(state, val) {
        state.availableBatchTempo = val;
    },
    SET_ORDER_AVAILABLE_BATCH(state, data) {
        state.orderAvailableBatch = data;
    },
    SET_DOCUMENT_BATCH_ALL(state, data) {
        let nc = [{
            NCDocEntry: "0",
            NCNumAtCard: 'No existe',
            NCVatSum: ".000000",
            NCWTSum: ".000000",
            NCDocTotal: ".000000",
            BaseEntry: "",
            BaseAmnt: ".00000"
        }];
        let invoices = [];
        _.forEach(data.res, item => {
            let invoice = item;
            if (invoice.Factura.length <= 0)
                return
            let dataNc = _.filter(data.creditNote, { 'BaseEntry': invoice.Factura[0].DocEntry });
            (dataNc.length > 0) ? invoice.Factura[0].nc = dataNc : invoice.Factura[0].nc = nc;
            invoices.push(invoice);
        });
        state.documentBatchAll = data.res;
    },
    SET_TYPE(state, type) {
        state.type = type;
    },
    SET_AVAILABLE_BATCH_AUTH(state, val) {
        state.availableBatchAuth = val;
    },
    SET_AVAILABLE_BATCH_AUTH_TEMPO(state, val) {
        state.availableBatchAuthTempo = val;
    },
    SET_PAGE_BATCH(state, val) {
        state.pageBatch = val;
    },
    SET_PAY_BATCH(state, val) {
        state.payBatchObj = val;
    },
    SET_EVIDENCES(state, val) {
        if (val.length > 0)
            state.evidences = val;
    },
    SET_LOCATION_SELECT(state, value) {
        state.locationSelect = value
    },
    SET_REMOVE_LOTE(state, value) {
        state.availableBatch.splice(_.findIndex(state.availableBatch, function (o) { return o.lote == value }), 1)
        state.availableBatchAuth.splice(_.findIndex(state.availableBatchAuth, function (o) { return o.lote == value }), 1)
    },


}
const actions = {
    getAvailableBatch({ commit, dispatch }) {
        commit('SET_LOADING', true);
        api.getAvailableBatch().then(async function (res) {
            await commit('SET_AVAILABLE_BATCH', res.data);
            await commit('SET_AVAILABLE_BATCH_TEMPO', res.data);
            await dispatch('filterAvailableBatch', res.data);
            await commit('SET_LOADING', false);
        }).catch(function () {
            commit('SET_LOADING', false);
        });
    },
    getBatchWithOutPay({ commit }) {
        commit('SET_LOADING', true);
        api.getBatchWithOutPay().then(async function (res) {
            await commit('SET_AVAILABLE_BATCH_AUTH', res.data);
            await commit('SET_AVAILABLE_BATCH_AUTH_TEMPO', res.data);
            await commit('SET_LOADING', false);
        }).catch(function () {
            commit('SET_LOADING', false);
        });
    },
    availableBatchOrder({ commit, state }, { type }) {
        if (type == 'pending') {
            commit('SET_AVAILABLE_BATCH', state.availableBatch);
        } else if (type == 'auth') {
            commit('SET_AVAILABLE_BATCH', state.batchWithoutPay);
        } else {
            commit('SET_AVAILABLE_BATCH', state.availableBatch);
        }
    },
    filterAvailableBatch(context) {
        let tmp = [];
        (context.state.type == 'pending') ? context.commit('SET_AVAILABLE_BATCH', context.state.availableBatchTempo) : context.commit('SET_AVAILABLE_BATCH_AUTH', context.state.availableBatchAuthTempo);
        if (context.state.locationSelect == null){
            context.commit('SET_LOCATION_SELECT',  { whs_code: '*', whs_name: 'TODOS' })
            return;
        }
        if (context.state.locationSelect.whs_code == '*')
            return;
        let baseData = (context.state.type == 'pending') ? context.state.availableBatch : context.state.availableBatchAuth;
        if (context.state.locationSelect.whs_code == 'FC') {
            tmp = _.filter(baseData, (obj) => {
                return _.some(obj.almacenes, (whs) => {
                    return _.startsWith(whs.whs_code, 'FC');
                });
            });
        } else if (context.state.locationSelect.whs_code == 'NM') {
            tmp = _.filter(baseData, (obj) => {
                return !_.some(obj.almacenes, (whs) => {
                    return _.startsWith(whs.whs_code, 'FC');
                });
            });
        } else {
            tmp = _.filter(baseData, (obj) => {
                return _.some(obj.almacenes, (whs) => {
                    return _.startsWith(whs.whs_code, context.state.locationSelect.whs_code);
                });
            });
        }
        (context.state.type == 'pending') ? context.commit('SET_AVAILABLE_BATCH', tmp) : context.commit('SET_AVAILABLE_BATCH_AUTH', tmp);
    },
    searchBatchWithDocuments(context, batch) {
        context.commit('SET_LOADING', true);
        context.commit('SET_DOCUMENT_BATCH_ALL', { 'res': [], 'creditNote': context.rootState.credit_note_sap.creditNote });
        api.searchBatchWithDocuments(batch).then(async function (res) {
            await context.commit('SET_DOCUMENT_BATCH_ALL', { 'res': res.data, 'creditNote': context.rootState.credit_note_sap.creditNote });
            context.commit('SET_LOADING', false);
        }).catch(function (error) {
            console.log(error)
            context.dispatch('notifications/add', { 'color': 'error', 'text': 'Error en conexión a sap', 'timeout': 5000, 'visibility': true }, { root: true });
            context.commit('SET_LOADING', false);
        })
    },
    updateSort(context, data) {
        context.commit('SET_LOADING', true);
        api.updateSort(data).then(async function () {
            context.commit('SET_LOADING', false);
            context.dispatch('notifications/add', { 'color': 'success', 'text': 'Orden actualizado ', 'timeout': 5000, 'visibility': true }, { root: true });
        }).catch(function (error) {
            context.dispatch('notifications/add', { 'color': 'error', 'text': + error, 'timeout': 5000, 'visibility': true }, { root: true });
        });
    },
    setType(context, type) {
        context.commit('SET_TYPE', type);
    },
    getPageBatch(context) {
        context.commit('SET_LOADING', true);
        api.getPageBatch().then(async function (res) {
            context.commit('SET_PAGE_BATCH', res.data);
            context.commit('SET_LOADING', false);
        }).catch(function (error) {
            context.commit('SET_LOADING', false);
            context.dispatch('notifications/add', { 'color': 'error', 'text': + error, 'timeout': 5000, 'visibility': true }, { root: true });
        });
    },
    removeItemByEntry(context, data) {
        context.commit('SET_LOADING', true);
        api.removeItemByEntry(data).then(async function (res) {
            context.commit('SET_LOADING', false);
            context.dispatch('getBatchWithOutPay')
            context.dispatch('getAvailableBatch')
            context.dispatch('getPageBatch')
            context.dispatch('notifications/add', { 'color': 'success', 'text': res.data.message, 'timeout': 5000, 'visibility': true }, { root: true });
        }).catch(function (error) {
            context.commit('SET_LOADING', false);
            context.dispatch('notifications/add', { 'color': 'error', 'text': error, 'timeout': 5000, 'visibility': true }, { root: true });
        });
    },
    payBatch(context, data) {
        context.commit('SET_LOADING', true);
        api.payBatch(data).then(async function (res) {
            context.commit('SET_LOADING', false);
            context.commit('SET_PAY_BATCH', res.data)
            context.commit('SET_REMOVE_LOTE', data.batch)
            context.dispatch('notifications/add', { 'color': 'success', 'text': res.data.message, 'timeout': 5000, 'visibility': true }, { root: true });
        }).catch(function (error) {
            context.commit('SET_LOADING', false);
            context.dispatch('notifications/add', { 'color': 'error', 'text': + error, 'timeout': 5000, 'visibility': true }, { root: true });
        });
    },
    getEvidences(context, data) {
        return new Promise((resolve) => {
            apiGoogle.getEvidences(data.entity_id, data.entity_type).then(function (res) {
                context.commit('SET_EVIDENCES', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_SET_LOADING_DOWNLOAD', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })

    },
    setLocationSelect(context, value) {
        context.commit('SET_LOCATION_SELECT', value)
        context.dispatch('filterAvailableBatch');

    },
    setLoading(context, value) {
        context.commit('SET_LOADING', value)
    },
    downloadFile(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING_DOWNLOAD', true);
            api.getAvailableBatchExport(data).then(function (res) {
                downloadFile(res.data, data.name + '.xlsx');
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Archivo descargado' }, { root: true });
                context.commit('SET_LOADING_DOWNLOAD', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING_DOWNLOAD', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
}
const getters = {
    getAuthOnly: state => {
        var tmpAuths = [];
        state.availableBatch.map(y => {
            y.authorizations.map(z => {
                tmpAuths.push(z);
            });
        });
        return tmpAuths;
    },
    getType(state) {
        return state.type
    }

}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
