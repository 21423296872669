const namespaced = true

import api     from '../../api/roles.js';
import _ from 'lodash';

const state = {
    roles : [],
}
const mutations = {
     MUTATION_SET_ROLES(state, value) {
          state.roles = value;
     },
     MUTATION_ADD_ROL(state, value){
          state.roles.push(value)
     },
     MUTATION_UPDATE_ROL(state, value){
          let roles = state.roles;
          state.roles = [];
          _.forEach(roles, rol => {
              if(rol.id != value.id){
                  state.roles.push(rol);
              }else{
                  state.roles.push(value);
              }
          });
     },
     MUTATION_DELETE_ROL(state, value){
          let roles = state.roles;
          state.roles = [];
          _.forEach(roles, rol => {
               if(rol.id != value){
                    state.roles.push(rol);
               }
          });
     },
}
const actions = {
      // Name Property
     getRoles(context) {
          return new Promise((resolve) => { 
               api.getRoles().then(function (res) {
                    context.commit('MUTATION_SET_ROLES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
               })
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_ROL', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_ROL', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_ROL', id);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     //Permitir/NoPermitor de Permisos a Roles
     allowAbilityToRol(context, { role, ability }){
          return new Promise((resolve, reject) => {
               api.allowAbilityToRol(role, ability).then(resp => {
                    context.commit('MUTATION_SET_ROLES', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     disallowAbilityToRol(context, { role, ability }){
          return new Promise((resolve, reject) => {
               api.disallowAbilityToRol(role, ability).then(resp => {
                    context.commit('MUTATION_SET_ROLES', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
