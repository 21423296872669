const namespaced = true
import api from '../../../api/sap/credit_note_sap.js';

const state = {
    creditNote: [],
}
const mutations = {
    SET_CREDIT_NOTE(state, data) {
        state.creditNote = data;
    }
}
const actions = {
    getCreditNote(context) {
        return new Promise((resolve) => {
            api.getCreditNote().then(function (res) {
                context.commit('SET_CREDIT_NOTE', res.data);
                resolve(res.data);
                context.dispatch('finance/addCreditNote', {}, {root :true});
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e },
                { root: true });
            })
        })
    },
}

const getters = {}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}