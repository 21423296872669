const namespaced = true
import api from '../../api/calendarGroups.js';
import { can } from './../../src/helpers/permissions.js';
import _ from 'lodash';

const state = {
    calendarGroups: [],
    calendarGroupsAll:[]
}
const mutations = {
    MUTATION_SET_CALENDAR_GROUPS(state, data) {
        if (can(data.root.configuration.userAuth.abilities, 'root-calendar')) {
            state.calendarGroups =data.data;
        } else if (can(data.root.configuration.userAuth.abilities, 'responsible-group-calendar')) {
            state.calendarGroupsAll =data.data;
            state.calendarGroups = _.map(_.filter(data.root.calendarGroupsUser.usersResponsible, ['user.id', data.root.configuration.userAuth.id]), 'group')
        } else if (can(data.root.configuration.userAuth.abilities, 'responsible-document')) {
            let userID = data.root.configuration.userAuth.id;
            let temporal = [];
            _.forEach(data.data, function (group) {
                _.forEach(group.users, function (user) {
                    if(userID==user.user.id){
                        temporal.push(group)
                    }
                })
            })
            state.calendarGroups =temporal;
        }
    }
}
const actions = {
    getCalendarGroups({commit,dispatch,rootState}) {
        return new Promise((resolve) => {
            api.getCalendarGroups().then(function (res) {
                commit('MUTATION_SET_CALENDAR_GROUPS', {data: res.data , root : rootState});
                resolve(res.data);
            }).catch((e) => {
                dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    store(context,data) {
        return new Promise((resolve) => {
            api.store(data).then(function (res) {
                context.commit('MUTATION_SET_CALENDAR_GROUPS', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    update({commit,dispatch,rootState},obj) {
        return new Promise((resolve) => {
            api.update(obj.id,obj.data).then(function (res) {
                commit('MUTATION_SET_CALENDAR_GROUPS', {data: res.data , root : rootState});
                resolve(res.data);
            }).catch((e) => {
               dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
}

const getters = {
    getGroupsEnabled(state, getters, rootState) {
        if (can(rootState.configuration.userAuth.abilities, 'root-calendar')) {
            return _.filter(state.calendarGroups, ['status', true])
        } else if (can(rootState.configuration.userAuth.abilities, 'responsible-group-calendar')) {
            return _.filter(state.calendarGroups, ['status', 'Activo'])
        } else if (can(rootState.configuration.userAuth.abilities, 'responsible-document')) {
            return _.filter(state.calendarGroups, ['status', true])
        }

    },
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}