const namespaced = true

import api from '../../api/userwarehouses.js';
const state = {
    userWarehouses: [],
    userWarehousesNot: [],
}
const mutations = {
    MUTATION_SET_USER_WAREHOUSES(state, data) {
        state.userWarehouses = data.data;
    },
    MUTATION_SET_USER_WAREHOUSES_NOT(state, data) {
        state.userWarehousesNot = data.data;
    }
}
const actions = {
    getID(context, id) {
        return new Promise((resolve) => {
            api.getID(id).then(function (res) {
                context.commit('MUTATION_SET_USER_WAREHOUSES', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    getIDNot(context, id) {
        return new Promise((resolve) => {
            api.getIDNot(id).then(function (res) {
                context.commit('MUTATION_SET_USER_WAREHOUSES_NOT', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    store(context, data){
        return new Promise((resolve, reject) => {
            api.store(data).then(resp => {
                resolve(resp.data);
            }).catch((e) => {
                reject(e)
            })
        });
    },
    deleteUserWhs(context, id){
        return new Promise((resolve, reject) => {
            api.deleteUserWhs(id).then(resp => {
                resolve(resp.data);
            }).catch((e) => {
                reject(e)
            })
        });
    },
    updateManager(context, data){
        return new Promise((resolve, reject) => {
            api.updateManager(data).then(resp => {
                resolve(resp.data);
            }).catch((e) => {
                reject(e)
            })
        });
    },
    
}

const getters = {
    getWhs(state){
        return state.userWarehouses;
     },
     getWhsNot(state){
        return state.userWarehousesNot;
     },
    
}
export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}