let prefix = '/api/finance/authorizations';


function getAvailableBatch(){
    return window.axios.get(prefix+'/available/batch');
}
function searchBatchWithDocuments(batch){
    return window.axios.get(prefix+'/batch/getAll/'+batch);
}
function updateSort(data){
    return window.axios.post(prefix+'/batch/updateSort',data)
}
function getBatchWithOutPay(){
    return window.axios.get(prefix+'/batch/getBatchWithOutPay')
}
function newAuth(data) {
    return window.axios.post(prefix+'/batch/newAuth',data)
}
function getPageBatch(){
    return window.axios.get(prefix+'/batch/getBatchPage');
}
function removeItemByEntry(data){
    return window.axios.post(prefix+'/batch/removeItemByEntry',data);
}
function payBatch(data){
    return window.axios.post(prefix+'/batch/payBatch',data);
}
function getAvailableBatchExport(data){
    return window.axios.post(prefix+'/available/batch',data,{ responseType: 'blob' });
}


export default {
    getAvailableBatch,searchBatchWithDocuments,updateSort,getBatchWithOutPay,newAuth,getPageBatch,removeItemByEntry,payBatch,getAvailableBatchExport
}