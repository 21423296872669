
let prefix = '/api/calendarFiles/CalendarGroups';


function getCalendarGroups(){
    return window.axios.get(prefix+'/index');
}

function store(data){
    return window.axios.post(prefix+'/store', data);
}

function update(id,data){
    return window.axios.patch(prefix+'/'+id+'/update',data);
}


export default {
    getCalendarGroups,store,update
    
}