const namespaced = true
import api from '../../api/calendarGroupsUser.js';
import _ from 'lodash';
import { can } from './../../src/helpers/permissions.js';

const state = {
    calendarGroupUser: [],
    usersResponsible: [],
}
const mutations = {
    MUTATION_SET_CALENDAR_GROUPS_USERS(state, data) {
        state.calendarGroupUser = data
    },
    MUTATION_SET_USER_RESPONSIBLE(state, data) {
        state.usersResponsible = _.filter(data, function (o) { return o.user_type; });
    }
}
const actions = {
    getCalendarGroupsUsers(context) {
        return new Promise((resolve) => {
            api.getCalendarGroupsUsers().then(function (res) {
                context.commit('MUTATION_SET_CALENDAR_GROUPS_USERS', res.data)
                context.commit('MUTATION_SET_USER_RESPONSIBLE', res.data)
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    store({ commit, dispatch, rootState }, data) {
        return new Promise((resolve) => {
            api.store(data).then(function (res) {
                commit('calendarGroup/MUTATION_SET_CALENDAR_GROUPS', { data: res.data, root: rootState }, { root: true })
                resolve(res.data);
            }).catch((e) => {
                dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    update({ commit, dispatch, rootState }, data) {
        return new Promise((resolve) => {
            api.update(data).then(function (res) {
                commit('calendarGroup/MUTATION_SET_CALENDAR_GROUPS', { data: res.data, root: rootState }, { root: true })
                resolve(res.data);
            }).catch((e) => {
                dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    delete({ commit, dispatch, rootState }, data) {
        return new Promise((resolve) => {
            api.deleteUser(data.user_id, data.calendar_group_id).then(function (res) {
                commit('calendarGroup/MUTATION_SET_CALENDAR_GROUPS', { data: res.data, root: rootState }, { root: true })
                resolve(res.data);
            }).catch((e) => {
                dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },

}

const getters = {
    getUser(state, getters, rootState) {
        if (can(rootState.configuration.userAuth.abilities, 'root-calendar')) {
            return _.uniqBy(_.map(state.calendarGroupUser, 'user'), 'id')
        } else if (can(rootState.configuration.userAuth.abilities, 'responsible-group-calendar')) {
            let temporal = [];
            _.forEach(rootState.calendarGroupsUser.usersResponsible, function (groups) {
                _.forEach(rootState.calendarGroup.calendarGroupsAll, function (group) {
                    if (groups.group.id == group.id && groups.user.id == rootState.configuration.userAuth.id) {
                        _.forEach(group.users, function (users) {
                            temporal.push(users.user)
                        })
                    }

                })
            })
            return _.uniqBy(temporal, 'id')
        } else if (can(rootState.configuration.userAuth.abilities, 'responsible-document')) {
            return _.filter(_.uniqBy(_.map(state.calendarGroupUser, 'user'), 'id'), { 'id': rootState.configuration.userAuth.id })
        }
    },
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}