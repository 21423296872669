
let prefix = '/api/user_warehouses';

function getID(id){
    return window.axios.get(prefix+'/getWhsId/'+id);
}
function getIDNot(id){
    return window.axios.get(prefix+'/getWhsNotId/'+id);
}
function store(data){
    return window.axios.post(prefix+'/store', data);
}
function deleteUserWhs(id){
    return window.axios.delete(prefix+'/delete/'+id);
}
function updateManager(data){
    return window.axios.patch(prefix+'/update',data);
}

export default {
    getID,store,getIDNot,deleteUserWhs,updateManager
}