const namespaced = true
import api from '../../../api/sap/groups_sap.js';

const state = {
    groups : [],
    itemsGroups: [],
}

const mutations = {
    MUTATION_SET_GROUPS(state, groups) {
        state.groups = groups
    },
    MUTATION_SET_ITEMS_GROUP(state, groups) {
        state.itemsGroups = groups
    }
}
const actions = {
    getGroups(context) {
        return new Promise((resolve) => {
            api.load_groups().then(function (res) {
                context.commit('MUTATION_SET_GROUPS', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
            })
        })
    },
    getItemGroups(context){
        return new Promise((resolve) => {
            api.getItemsGropus().then(function (res) {
                context.commit('MUTATION_SET_ITEMS_GROUP', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
            })
        })
    },

}

const getters = {
    getGroupsItem(state){
        return state.groups
    }

}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}

