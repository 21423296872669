const namespaced = true
import api from '../../api/costcenter';

const state = {
    cost_center: [],
    cost_center_all: [],
    cost_center_assigned: [],
    cost_center_available: [],
}
const mutations = {
    SET_COST_CENTER(state, data) {
        state.cost_center = data
    },
    SET_COST_CENTER_ALL(state, data) {
        state.cost_center_all = data
    },
    SET_COST_CENTER_ASSIGNED(state, data) {
        state.cost_center_assigned = data
    },
    SET_COST_CENTER_AVAILABLE(state, data) {
        state.cost_center_available = data
    },


}
const actions = {
    getCostCenterUser(context, data) {
        context.commit('SET_COST_CENTER', data)
    },
    getCostCenterAll(context, all = false) {
        return new Promise((resolve) => {
            api.getCostCenter(all).then(function (res) {
                context.commit('SET_COST_CENTER_ALL', res.data.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
                
            })
        })
    },
    getCostCenterNot(context, id) {
        return new Promise((resolve) => {
            api.getCostCenterNot(id).then(function (res) {
                context.commit('SET_COST_CENTER_AVAILABLE', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
                
            })
        })
    },
    getCostCenter(context, id) {
        return new Promise((resolve) => {
            api.getCostCenterAvailable(id).then(function (res) {
                context.commit('SET_COST_CENTER_ASSIGNED', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
                
            })
        })
    },
    addCostCenter(context, data) {
        return new Promise((resolve) => {
            api.addCostCenter(data).then(function (res) {
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
                
            })
        })
    },
    deleteCostCenterRelation(context, id){
        return new Promise((resolve) => {
            api.deleteCostCenterRelation(id).then(function (res) {
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
                
            })
        })
    },
    store(context, data){
        return new Promise((resolve) => {
            api.store(data).then(function (res) {
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
                
            })
        })
    },
    deleteCostCenter(context, id){
        return new Promise((resolve) => {
            api.deleteCostCenter(id).then(function (res) {
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
                
            })
        })
    },
    updateCostCenter(context, data){
        return new Promise((resolve) => {
            api.updateCostCenter(data).then(function (res) {
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
                
            })
        })
    },

}
const getters = {}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}