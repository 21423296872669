const namespaced = true
import api from '../../api/batch-service';

const state = {
    batch: [],
}

const mutations = {
    SET_batch(state, value) {
        state.batch = value;
    }
}

const actions = {
    newAuthBatch(context, data) {
        return new Promise((resolve, reject) => {
            context.dispatch('batch/setLoading',  true, { root: true });
            api.newAuth(data).then(resp => {
                resolve(resp)
            }).catch((error) => {
                reject(error)
            })
        })
            .then(function (resp) {
                context.commit('SET_batch', resp.data);
                context.dispatch('batch/getAvailableBatch', {}, { root: true });
                context.dispatch('batch/getBatchWithOutPay', {}, { root: true });
                
                context.dispatch('batch/setLoading',  false, { root: true });
                
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Autorización concedida' }, { root: true });
            })
            .catch((error) => {
                context.dispatch('batch/setLoading',  false, { root: true });
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': error.messages }, { root: true });

            })


    }

}
const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
