let prefix = "/api/purchase/requisition";
import _ from 'lodash';

function getRequisition() {
    return window.axios.get(prefix + "/index");
}
function store(data) {
    return window.axios.post(prefix + "/store", data);
}
function authorize(data) {
    return window.axios.post(prefix + "/authorize", data);
}
function cancel(id) {
    return window.axios.patch(prefix + "/cancel/" + id);
}
function update(data) {
    return window.axios.post(prefix + "/update", data);
}
function getPending(data) {
    let result = _.map(data.applicants, "id");
    return window.axios.get(prefix + "/pending", { params: result });
}
function getIndex(page, perPage) {
    return window.axios.get(prefix + "/index/" + page + "/" + perPage);
}

function getClosed(initial, final, page, perPage ,agents , applicants) {
  
    let data = {'agents' :  _.map(agents, "slp_code"),'applicants' :  _.map(applicants, "emp_id"),}
    return window.axios.get(
        prefix + "/closed/" + initial + "/" + final + "/" + page + "/" + perPage , { params: data }
    );
}
function getAuthorized(page, perPage) {
    return window.axios.get(prefix + "/authorized/" + page + "/" + perPage);
}
function getClosedLinePurchases(DocEntry) {
    return window.axios.get(prefix + "/closeLinePurchases/" + DocEntry);
}
export default {
    getRequisition,
    store,
    authorize,
    cancel,
    update,
    getPending,
    getClosed,
    getClosedLinePurchases,
    getIndex,
    getAuthorized,
};
