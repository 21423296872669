let prefix = '/api/finance/';

function create(data){
    return window.axios.post(prefix+'batch/create',data);
}
function getBatch(data) {
    return window.axios.get(prefix+'batch/'+data)
}

function documentByID(type,idDocument) {
    return window.axios.get(prefix+'documents/'+type+'/'+idDocument);
}

export default {
    create,getBatch,documentByID
}
