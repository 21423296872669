const namespaced = true

import api     from '../../api/abilities.js';
import _ from 'lodash';

const state = {
    all_abilities : [],
}
const mutations = {
     MUTATION_SET_ABILITIES(state, value) {
          state.all_abilities = value;
     },
     MUTATION_ADD_ABILITY(state, value){
          state.all_abilities.push(value)
     },
     MUTATION_UPDATE_ABILITY(state, value){
          let all_abilities = state.all_abilities;
          state.all_abilities = [];
          _.forEach(all_abilities, ability => {
              if(ability.id != value.id){
                  state.all_abilities.push(ability);
              }else{
                  state.all_abilities.push(value);
              }
          });
     },
     MUTATION_DELETE_ABILITY(state, value){
          let all_abilities = state.all_abilities;
          state.all_abilities = [];
          _.forEach(all_abilities, ability => {
               if(ability.id != value){
                    state.all_abilities.push(ability);
               }
          });
     },
}
const actions = {
      // Name Property
     getAbilities(context, all = false) {
          return new Promise((resolve) => { 
               api.getAbilities(all).then(function (res) {
                    context.commit('MUTATION_SET_ABILITIES', res.data);
                    resolve(res.data);
               }).catch(function (error) {
                    console.log(error)
               })
          })
     },
     store(context, data){
          return new Promise((resolve, reject) => {
               api.store(data).then(resp => {
                    context.commit('MUTATION_ADD_ABILITY', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     update(context, {id, data}){
          return new Promise((resolve, reject) => {
               api.update(id, data).then(resp => {
                    context.commit('MUTATION_UPDATE_ABILITY', resp.data);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
     delete(context, id){
          return new Promise((resolve, reject) => {
               api.deleted(id).then(resp => {
                    context.commit('MUTATION_DELETE_ABILITY', id);
                    resolve(resp.data);
               }).catch(error => {
                    reject(error);
               });
          });
     },
}

const getters = {    

}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}
