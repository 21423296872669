const namespaced = true
import api from '../../../../api/sap/payment/terms_sap.js';

const state = {
    loading: false,
    payments_terms_sap: [],
}

const mutations = {
    SET_LOADING(state, val) {
        state.loading = val;
    },
    MUTATION_SET_PAYMENT_TERMS_SAP(state, data){
        state.payments_terms_sap = data;
    },

}
const actions = {
    getPaymentTerms(context, data){
        api.getPaymentTerms(data.date1, data.date2).then(function (res) {
            context.commit('MUTATION_SET_PAYMENT_TERMS_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
}

const getters = {


}
export default {
    namespaced,
    state,
    getters,
    mutations,
    actions
}