const namespaced = true
import api from './../../api/evidences.js';
import apiGoogle from './../../api/googleDrive.js';
import apiFinance from './../../api/finance.js';
import apiPurchaseTravel from './../../api/purchaseTravel.js';
import _ from 'lodash';

const state = {
    evidences: [],
    evidencesType: [
        { id: 'RQ', description: 'Requisición', idDocument: 0 },
        { id: 'OC', description: 'Orden de compra', idDocument: 1 },
        { id: 'OV', description: 'Orden de venta', idDocument: 2},
        { id: 'DL', description: 'Entrada/Ticket', idDocument: 3 },
        { id: 'IN', description: 'Factura', idDocument: 4 }
    ],
    evidenceDocumentType: [
        { id: 1, description: 'Análisis de laboratorio' },
        { id: 2, description: 'Carta porte' },
        { id: 3, description: 'Comprobante de pagos' },
        { id: 4, description: 'Comprobante de depositos' },
        { id: 5, description: 'Comunicado' },
        { id: 6, description: 'Consumo general de gasolina' },
        { id: 7, description: 'Cotización' },
        { id: 8, description: 'Cuadro comparativo' },
        { id: 9, description: 'Cuentas' },
        { id: 10, description: 'Dibujo' },
        { id: 11, description: 'Estado de cuenta' },
        { id: 12, description: 'Enlaces de productos' },
        { id: 13, description: 'Entregas' },
        { id: 14, description: 'Evidencia de montaje' },
        { id: 15, description: 'Evidencia gas LP' },
        { id: 16, description: 'Evidencia general' },
        { id: 17, description: 'Evidencia paquetería' },
        { id: 18, description: 'Factura' },
        { id: 19, description: 'Formato' },
        { id: 20, description: 'Fotografía' },
        { id: 21, description: 'Kardex' },
        { id: 22, description: 'Plano' },
        { id: 23, description: 'Póliza de registro contable' },
        { id: 24, description: 'Respaldos' },
        { id: 25, description: 'Reporte de Calidad' },
        { id: 26, description: 'Rol de transporte' },
        { id: 27, description: 'Señalamientos' },
        { id: 28, description: 'Tabla consumo' },
        { id: 29, description: 'Ticket' },
        { id: 30, description: 'Ticket de bascula' },
        { id: 31, description: 'Ticket de flete' },
        { id: 32, description: 'Vales' },
        { id: 33, description: 'Orden de compra' },
        { id: 34, description: 'Otro' },
        { id: 35, description: 'Orden de venta'}
    ],
    selectedType: { id: null, description: null, idDocument: null },
    selectedID: null,
    selectedTypeDocument: null,
    flagEvidences: false,
    flagEvidencesUpload: false,
    documentSelect: null,
    flagAddTicket: false,
    messageTicket: "Agregando ticket",
}
const mutations = {
    MUTATION_SET_EVIDENCES(state, data) {
        state.evidences = data;
    },
    MUTATION_SET_SELECTED_TYPE(state, value) {
        state.selectedType = value;
    },
    MUTATION_SET_SELECTED_ID(state, value) {
        state.selectedID = value;
    },
    MUTATION_SET_SELECTED_TYPE_DOCUMENT(state, value) {
        state.selectedTypeDocument = value;
    },
    DELETE_EVIDENCES(state, value) {
        state.evidences.splice(_.findIndex(state.evidences, function (o) { return o.id == value }), 1)
    },
    MUTATION_SET_FLAG_EVIDENCES(state, value) {
        state.flagEvidences = value;
    },
    MUTATION_SET_FLAG_ADD_TICKET(state, value) {
        state.flagAddTicket = value;
    },
    MUTATION_SET_FLAG_EVIDENCES_UPLOAD(state, value) {
        state.flagEvidencesUpload = value;
    },
    PUSH_EVIDENCES(state, value) {
        _.forEach(value, function (item) {
            state.evidences.push(item)
        });
    },
    MUTATION_SET_DOCUMENT_SELECT(state, data) {
        state.documentSelect = data;
    },
    CLEAR_DATA(state) {
        state.documentSelect = null;
        state.evidences = [];
    },
    PUSH_PURCHASE_ORDER_TICKET(state, value) {
        state.documentSelect[0].Tickets.push(value)
    },
    DELETE_PURCHASE_ORDER_TICKET(state, value) {
        state.documentSelect[0].Tickets.splice(_.findIndex(state.documentSelect[0].Tickets, function (o) { return o.id == value }), 1)
    },
    MUTATION_SET_MESSAGE(state, value) {
        state.messageTicket = value
    },
}
const actions = {
    async getEvidences(context, data) {
        context.commit('MUTATION_SET_FLAG_EVIDENCES', true);
        try {
            const res = await apiGoogle.getEvidences(data.entity_id, data.entity_type);
            context.commit('MUTATION_SET_EVIDENCES', res.data);
            context.commit('MUTATION_SET_FLAG_EVIDENCES', false);
            return res.data;
        } catch (e) {
            context.commit('MUTATION_SET_FLAG_EVIDENCES', false);
            context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
        }
    },
    update(context, obj) {
        return new Promise((resolve) => {
            api.update(obj.id, obj.data).then(function (res) {
                context.commit('DELETE_EVIDENCES', res.data.id);
                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Documento eliminado' }, { root: true });
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    StoreEvidences(context, obj) {
        return new Promise((resolve) => {
            context.commit('MUTATION_SET_FLAG_EVIDENCES_UPLOAD', true);
            apiGoogle.StoreEvidences(obj.entity_id, obj.entity_type, obj.evidence_type, obj.files).then(function (res) {
                context.commit('PUSH_EVIDENCES', res.data);
                context.commit('MUTATION_SET_FLAG_EVIDENCES_UPLOAD', false);
                resolve(res.data);
                context.dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': 'Documentos anexados' }, { root: true });
            }).catch((e) => {
                context.commit('MUTATION_SET_FLAG_EVIDENCES_UPLOAD', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    setEvidences(context, data) {
        context.commit('MUTATION_SET_EVIDENCES', data);
    },
    setSelectedType(context, value) {
        context.commit('MUTATION_SET_SELECTED_TYPE', value);
        context.commit('CLEAR_DATA', []);
    },
    setSelectedID(context, value) {
        context.commit('MUTATION_SET_SELECTED_ID', value);
        context.commit('CLEAR_DATA', []);
    },
    setSelectedTypeDocument(context, value) {
        context.commit('MUTATION_SET_SELECTED_TYPE_DOCUMENT', value);
    },
    documentByID(context, obj) {
        return new Promise((resolve) => {
            apiFinance.documentByID(obj.type, obj.idDocument).then(function (res) {
                context.commit('MUTATION_SET_DOCUMENT_SELECT', res.data);
                resolve(res.data);
            }).catch((e) => {
                context.commit('MUTATION_SET_DOCUMENT_SELECT', []);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });

            })
        })
    },
    setClearData(context) {
        context.commit('CLEAR_DATA');
        context.commit('MUTATION_SET_SELECTED_TYPE', { id: null, description: null, idDocument: null });
        context.commit('MUTATION_SET_DOCUMENT_SELECT', []);
        context.commit('MUTATION_SET_SELECTED_ID', null);
        context.commit('MUTATION_SET_FLAG_EVIDENCES', false);

    },
    addTicketPurchaseOrder(context, data) {
        context.commit('MUTATION_SET_FLAG_ADD_TICKET', true);
        context.commit('MUTATION_SET_MESSAGE', 'Agregando Ticket..');
        return new Promise((resolve) => {
            apiPurchaseTravel.store(data).then(function (res) {
                context.commit('PUSH_PURCHASE_ORDER_TICKET', res.data);
                context.commit('MUTATION_SET_FLAG_ADD_TICKET', false);
                resolve(res.data);
            }).catch((e) => {
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
                context.commit('MUTATION_SET_FLAG_ADD_TICKET', false);
            })
        })
    },
    deleteTicketPurchaseOrder({ commit, dispatch, rootState }, data) {
        commit('MUTATION_SET_FLAG_ADD_TICKET', true);
        commit('MUTATION_SET_MESSAGE', 'Eliminado Ticket..');
        return new Promise((resolve) => {
            apiPurchaseTravel.deleteTicket(data.id).then(function (res) {
                commit('DELETE_PURCHASE_ORDER_TICKET', data.id);
                commit('MUTATION_SET_FLAG_ADD_TICKET', false);
                dispatch('notifications/add', { 'color': 'success', 'visibility': true, 'timeout': 5000, 'text': res.data.message }, { root: true });
                (rootState.invoice_customer_sap_report.documentSelect ==  data.invoice)?dispatch('invoice_customer_sap_report/setDocumentSelect', null, { root: true }):null;
                resolve(res.data);
            }).catch((e) => {
                dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
                commit('MUTATION_SET_FLAG_ADD_TICKET', false);
            })
        })
    },
}

const getters = {
    evidencesType(state) {
        return state.evidencesType
    },
    evidenceDocumentType(state) {
        return state.evidenceDocumentType
    },
    evidencesTypeSelect(state) {
        return state.selectedType.description
    },
    evidencesTypeSelectId(state) {
        return state.selectedType.idDocument
    },
    DocSelectLines(state) {
        let result = [];
        switch (state.selectedType.id) {
            case 'OC':
                result = _.map(state.documentSelect, ({ Dscription, unitMsr, Quantity, Price, GTotal, DocNum, CardName, SlpName }) => ({ Dscription, unitMsr, Quantity, Price, GTotal, DocNum, CardName, SlpName }));
                break;
            case 'RQ':
                result = _.map(state.documentSelect, ({ Dscription, UnitMsr, Quantity, DocEntry, solicitante, autorizo }) => ({ Dscription, UnitMsr, Quantity, DocEntry, solicitante, autorizo }));
                break;
            case 'DL':
                result = _.map(state.documentSelect, ({ DocNum, ItemCode, Dscription, Quantity, U_HF_RMERCA, U_BXP_FBRUTO, U_HF_HORACREACION, DocEntry, unitMsr, WhsCode }) => ({ DocNum, ItemCode, Dscription, Quantity, U_HF_RMERCA, U_BXP_FBRUTO, U_HF_HORACREACION, DocEntry, unitMsr, WhsCode }));
                break;
            case 'IN':
                result = _.map(state.documentSelect, ({ WhsCode, Dscription, Quantity, unitMsr, DocDueDate, DocNum, NumATCard, CardName }) => ({ WhsCode, Dscription, Quantity, unitMsr, DocDueDate, DocNum, NumATCard, CardName }));
                break;
            case 'OV':
                result = _.map(state.documentSelect, ({ Dscription, unitMsr, Quantity, Price, GTotal, DocNum, CardName, SlpName }) => ({ Dscription, unitMsr, Quantity, Price, GTotal, DocNum, CardName, SlpName }));
                break;
            default:
                result = [];
                break;

        }
        return result;
    },
    docSelectDocEntry(state) {
        return (state.documentSelect != null) ? state.documentSelect[0].DocEntry : null;
    },
    SelectTypeId(state) {
        return (state.selectedType != null) ? state.selectedType.id : null;
    },
    getTicketsPurchases(state) {
        return (state.selectedType != null) ? state.documentSelect[0].Tickets : null
    },


}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}