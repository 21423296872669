
let prefix = '/api/purchase-travel';


function store(data){
    return window.axios.post(prefix+'/store', data);
}
function deleteTicket(ticket){
    return window.axios.post(prefix+'/'+ticket+'/delete');
}

export default {
    store,deleteTicket
}