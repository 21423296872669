let prefix = '/api/sap/supplier-invoices';
let prefixReport = '/api/sap/supplier-invoices-report';

function getAuthInvoices(date1,date2,cardCode){
    return window.axios.get(prefix+'/authorizations_invoices?date1='+date1+'&date2='+date2+'&cardCode='+cardCode);
}
function getInvoicesSupplier(date1, date2){
    return window.axios.get(prefix+'/index?date1='+date1+'&date2='+date2);
}
function getGenaralPurchases(date1, date2){
    return window.axios.get(prefixReport+'/general?date1='+date1+'&date2='+date2);
}
function downloadExcel(date1, date2) {
    return window.axios.post(prefixReport+'/general/excel?date1='+date1+'&date2='+date2, { date1: date1, date2: date2 }, { responseType: 'blob' });
}
function getExpenses(date1, date2){
    return window.axios.get(prefixReport+'/expense?date1='+date1+'&date2='+date2);
}
function downloadExcelExpense(project, date1, date2) {
    return window.axios.post(prefixReport+'/expense/excel?project='+project+'&date1='+date1+'&date2='+date2, { date1: date1, date2: date2 }, { responseType: 'blob' });
}
function getDevolucionIva(date1, date2, DocCur , cardName){
    return window.axios.get(prefix+'/DevolucionIva?date1='+date1+'&date2='+date2+'&DocCur='+DocCur+'&cardName='+cardName);
}
function getDevolucionIvaExport(data){
    return window.axios.post(prefix+'/DevolucionIva/export',data,{ responseType: 'blob' });
}
function getInvoicesSupplierShow(docEntry){
    console.log(docEntry);
    return window.axios.get(prefix+'/show/'+docEntry);
}
function getReportPurchasesInvoices(docEntry){
    return window.axios.get(prefix+'/reportPurchasesInvoices/'+docEntry);
}

function byArticule(page,perPage,tipo,item) {
    return window.axios.get(prefix+'/byHistory/'+page+'/'+perPage+'?&item='+item+'&tipo='+tipo)
   
}

function getInvoceByitem(page,perPage,date1,date2, item,cardCode) {
    return window.axios.get(prefix+'/getInvoceByitem/'+page+'/'+perPage+'?date1='+date1+'&date2='+date2+'&item='+item+'&cardCode='+cardCode)
   
}

function getPurchaseOrder(docEntry){
    return window.axios.get(prefix+'/show/'+docEntry);
}

export default {
    getAuthInvoices,
    getInvoicesSupplier,
    getGenaralPurchases,
    getExpenses,
    downloadExcel,
    downloadExcelExpense,
    getDevolucionIva,
    getDevolucionIvaExport,
    getInvoicesSupplierShow,
    getReportPurchasesInvoices,
    byArticule,
    getInvoceByitem,
    getPurchaseOrder
}