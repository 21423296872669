import _ from 'lodash'

export function number(value) {
    return _.floor(value, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export function date(value) {
    return (value.length <= 10) ? value : value.substr(0, value.length - 3);
}


export function AuthorizationFormat(value) {
    let words = value.split(" ");
    let initials= ''
    for (let i = 0; i < words.length; i++) 
        initials += words[i].charAt(0);
    return initials;
}

export function timeFormat(value = null) {
    if (typeof value === 'undefined' || value == null) {
        return '';
    } else {
        var x = '';
        x = (value + '').length == 1 ? (value = '00' + value) : value + '';
        x = (value + '').length == 2 ? (value = '00' + value) : value + '';
        x = (value + '').length == 3 ? (value = '0' + value) : value + '';
        x = [x.slice(0, 2), ':', x.slice(2)].join('');
        return x.replace('::', ':');
    }

}

export function statusString(value) {
    let name = '';
    switch (value) {
        case 'warning':
            name = 'Periodo de renovación'
            break;
        case 'error':
            name = 'Vencidó'
            break;
        case 'success':
            name = 'Vigente'
            break;
    }
    return name;

}

export function dataFormatWord(date) {
    var mount = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    let tempoDate = new Date(date);
    tempoDate.setMinutes(tempoDate.getMinutes() + tempoDate.getTimezoneOffset())
    return tempoDate.getDate() + ' de ' + mount[tempoDate.getMonth()] + ' de ' + tempoDate.getFullYear();
}

export function dateFormat(value) {
    const date = new Date(value);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Sumamos 1 porque los meses se indexan desde 0
    const year = date.getFullYear().toString();

    return `${day}-${month}-${year}`;
}

export function kbbtsToMbs(kbts) {
    var rs = ((parseInt(kbts)) / 1024) / 1024;
    return rs.toFixed(2);
}

export function dayForPay(date){
   
   var dateToday = new Date();
   var partDate = dateFormat(date).split('-');
   var day = parseInt(partDate[0]);
   var month = parseInt(partDate[1]) - 1; 
   var year = parseInt(partDate[2]);
   var dateNew = new Date(year, month, day);
   var available  = dateToday - dateNew;
   var result = Math.floor(available / (1000 * 60 * 60 * 24));

   return result * -1; 
}

export function sapCodeToText(code) {
    const table = {
        18: () => {
            return 'Factura de proveedor'
        },
        20: () => {
            return 'Entrada'
        },
        22: () => {
            return 'Orden de compra'
        },
    }
    return table[code] ? table[code]() : 'No existe información';
}