const namespaced = true

const state = {
    dateInitial: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split("T")[0],
    dateFinal: new Date(new Date().getFullYear(), new Date().getMonth(), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()).toISOString().split("T")[0],
    listDays: [
        { id: 1, name: 'Hoy', start_date: new Date(Date.now()).toISOString().substr(0, 10) },
        { id: 2, name: 'Hace 3 dias', start_date: new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10) },
        { id: 3, name: 'Hace 1 semana', start_date: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10) },
        { id: 4, name: 'Hace 1 mes ', start_date: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10) },
        { id: 5, name: 'Hace 6 meses ', start_date: new Date(Date.now() - 180 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10) },
        { id: 6, name: 'Manual', start_date: 'Manual' }
    ],

}
const mutations = {
    MUTATION_SET_DATEINITIAL(state, data) {
        state.dateInitial = data;
    },
    MUTATION_SET_DATEFINAL(state, data) {
        state.dateFinal = data;
    },
    MUTATION_SET_RESET_DATES(state) {
        state.dateInitial = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split("T")[0],
        state.dateFinal = new Date(new Date().getFullYear(), new Date().getMonth(), new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()).toISOString().split("T")[0]

    }
}
const actions = {
    setInitial(context, data) {
        context.commit('MUTATION_SET_DATEINITIAL', data);
    },
    setFinal(context, data) {
        context.commit('MUTATION_SET_DATEFINAL', data);
    },
    resetDates(context) {
        context.commit('MUTATION_SET_RESET_DATES');
    },
}

const getters = {}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}