
let prefix = '/api/walmart/deliveries';

function getCatLitterPOD(data){
    return window.axios.post(prefix+'/getCatLitterPOD',data);
}


export default {
    getCatLitterPOD
}