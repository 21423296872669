
let prefix = '/api/calendarFiles/CalendarGroupsUsers';

function getCalendarGroupsUsers(){
    return window.axios.get(prefix+'/index');
}

function store(data){
    return window.axios.post(prefix+'/store', data);
}

function update(data){
    return window.axios.patch(prefix+'/update',data);
}

function deleteUser(idUser ,idGroup){
    return window.axios.delete(prefix+'/delete/'+idUser+'/'+idGroup);
}

export default {
    store,update,getCalendarGroupsUsers,deleteUser
    
}