const namespaced = true
import api from '../../api/activityLog.js';

const state = {
    data : []
}
const mutations = {
    MUTATION_SET_DATA(state, data){
         state.data = data;
    }
}
const actions = {
    getActivityLog(context) {
        api.getActivityLog().then(function (res) {
            context.commit('MUTATION_SET_DATA', res.data)
        }).catch(function (error) {
            console.log(error)
        })
    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}