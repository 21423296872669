const namespaced = true
import api from '../../api/finance.js';
import _ from 'lodash'

const state = {
    operation: { 'color': '', 'visibility': true, 'timeout': 5000, 'text': '' },
    universe_list: [],
    candidates_list: [],
    selected_list: [],
    selected_batch: [],
    lote_name: null,
}
const mutations = {

    SET_UNIVERSE(state, data) {
        state.universe_list = data;
    },
    SET_CANDIDATES(state, data) {
        state.candidates_list = data;
    },
    SET_OPERATION(state, { type, response, message }) {
        state.operation = { 'type': type, 'response': response, 'message': message };
    },
    SET_SELECTED_LIST(state, { data, sort }) {
        if (state.selected_list.indexOf(data) == -1) {
            data.map(x => x.sort = sort);
            state.selected_list.push(data);
            state.operation = { 'color': 'success', visibility: true, 'timeout': 3000, 'text': 'Factura agregada correctamente' };
        } else {
            state.operation = { 'color': 'error', visibility: true, 'timeout': 3000, 'text': 'Esa factura ya se encuentra en la lista' };
        }
    },
    ORDER_SELECTED_LIST_BY(state) {
        state.selected_list = _.orderBy(state.selected_list, [(x) => { return +x[0].sort }], ['asc']);
    },
    REMOVE_ITEM_FROM_LIST(state, data) {
        state.selected_list.splice(state.selected_list.indexOf(data), 1);
    },
    CLEAR_SELECTED_LIST(state) {
        state.selected_list = [];
    },
    SET_SELECTED_BATCH(state, data) {
        state.selected_batch = data;
    },
    SET_SELECTED_LIST_ALL(state, data) {
        state.selected_list = data;
    },
    SET_ORDER_SELECTED_LIST_BY_V2(state) {
        state.selected_list = _.orderBy(state.selected_list, [(x) => { return +x.sort }], ['asc']);
    },
    SET_ADD_CREDIT_NOTE_INVOICE(state, data) {
        let nc = [{
            NCDocEntry: "0",
            NCDocTotal: ".000000",
            NCNumAtCard: 'No existe',
            NCVatSum: ".000000",
            NCWTSum: ".000000",
        }];
        let invoices = [];
        _.forEach(data.finance.universe_list, item => {
            let invoice = item;
            let dataNc = _.filter(data.credit_note_sap.creditNote, { 'BaseEntry': item[0].DocEntry });
            (dataNc.length > 0) ? invoice[0].nc = dataNc : invoice[0].nc = nc;
            invoices.push(invoice);
        });
        state.universe_list = invoices;
    },
    SET_LOTE_NAME(state, value) {
        state.lote_name = value;
    },
}
const actions = {
    setUniverse(context, data) {
        context.commit('SET_UNIVERSE', data);
        context.dispatch('credit_note_sap/getCreditNote', {}, { root: true });
    },
    searchCandidates(context, { search }) {
        if (_.isEmpty(search)) {
            context.dispatch('notifications/add', { 'color': 'error', 'text': 'Busqueda vacia', 'timeout': 5000, 'visibility': true }, { root: true });
        } else {
            let searchTerms = search.toLowerCase().split(" ");
            let tmp = _.filter(context.state.universe_list, (universe) => {
                return _.some(universe, (item) => {
                    return _.every(searchTerms, (term) => {
                        return _.some([
                            item.DocNum,
                            item.CardName,
                            item.DocDueDate,
                            item.DocCur
                        ].map(prop => prop.toString().toLowerCase().includes(term)));
                    });
                });
            });
            (tmp.length == 0) ? context.dispatch('notifications/add', { 'color': 'error', 'text': 'Sin Resultados', 'timeout': 5000, 'visibility': true }, { root: true }) :
                context.dispatch('notifications/add', { 'color': 'success', 'text': 'Resultados recuperados', 'timeout': 5000, 'visibility': true }, { root: true });

            context.commit('SET_CANDIDATES', tmp);
        }
    },
    pushToSelectedList(context, { data, sort }) {
        let banner = true;
        _.forEach(context.state.selected_list, batch => {
            if (batch[0].DocCur != data[0].DocCur) {
                context.dispatch('notifications/add', { 'color': 'error', 'text': 'Factura con diferente divisa', 'timeout': 5000, 'visibility': true }, { root: true });
                banner = false;
                return;
            }
        })
        if (banner) {
            context.commit('SET_OPERATION', { 'type': '', 'response': null, 'message': '' });
            context.commit('SET_SELECTED_LIST', { data, sort });
            context.commit('ORDER_SELECTED_LIST_BY');
        }
    },
    removeFromList(context, data) {
        context.commit('REMOVE_ITEM_FROM_LIST', data);
        context.dispatch('reorder', { root: true });
    },
    reorder(context) {
        context.commit('ORDER_SELECTED_LIST_BY')
    },
    createBatch(context, data) {
        api.create(data).then(function (res) {
            context.commit('CLEAR_SELECTED_LIST');
            context.commit('SET_CANDIDATES', []);
            context.dispatch('notifications/add', { 'color': 'success', 'text': 'Lote generado : ' + res.data, 'timeout': 5000, 'visibility': true }, { root: true });
            context.dispatch('invoice_supplier_sap/getAuthInvoices', {date1: '', date2: '', cardCode: ''}, { root: true });
        }).catch(function (error) {
            console.log(error)
        })

    },
    setSelectedBatch(context, data) {
        context.commit('SET_SELECTED_BATCH', data);
    },
    getLocalBatch(context, data) {
        api.getBatch(data).then(function (res) {
            context.commit('SET_SELECTED_LIST_ALL', res.data);
        }).catch(function (error) {
            console.log(error)
        })
    },
    async reorderFromServer(context, data) {
        await context.dispatch('batch/updateSort', { 'id': data.id, 'sort': data.sort }, { root: true });
        await context.commit('SET_ORDER_SELECTED_LIST_BY_V2');
    },
    addCreditNote(context) {
        context.commit('SET_ADD_CREDIT_NOTE_INVOICE', context.rootState);

    },
    setLoteName(context, value) {
        context.commit('SET_LOTE_NAME', value);

    }

}

const getters = {
    getSelectList: (state) => {
        return _.groupBy(state.selected_list, item => item[0].CardCode);
    },
    getSelectListTotal: (state) => {
        return state.selected_list.length
    },
    getSelectListBySupplier: (state, getter) => {
        return _.mapValues(getter.getSelectList, items => _.size(items));
    },
    getMontoLote: (state) => {
        let sum = _.sumBy(state.selected_list, x => {
            let subtotal = parseFloat(x[0].Subtotal);
            let ncDocTotal = parseFloat(x[0].nc[0].NCDocTotal);
            let ncWTSum = parseFloat(x[0].nc[0].NCWTSum);

            return subtotal - ncDocTotal + ncWTSum;
        });
        return sum
    }
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
}