
let prefix = '/api/costcenter';

function getCostCenter(){
    return window.axios.get(prefix+'/index');
}
function getCostCenterNot(id){
    return window.axios.get(prefix+'/getCostCenterNot/'+id);
}
function getCostCenterAvailable(id){
    return window.axios.get(prefix+'/getCostCenter/'+id);
}
function addCostCenter(data){
    return window.axios.post(prefix+'/addcostocenter',data);
}
function deleteCostCenterRelation(id){
    return window.axios.delete(prefix+'/deleterelation/'+id);
}
function deleteCostCenter(id){
    return window.axios.delete(prefix+'/delete/'+id);
}
function store(data){
    return window.axios.post(prefix+'/store',data);
}
function updateCostCenter(data){
    return window.axios.patch(prefix+'/updateCostCenter',data);
}

export default {
    getCostCenter,getCostCenterNot,getCostCenterAvailable,addCostCenter,deleteCostCenterRelation,store,deleteCostCenter,updateCostCenter
}